import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { Context } from '../contextApi/context';
import './css/dashboard.css';
import axios from 'axios';
import UserService from './../services/UserService';
import Notification from '../common/notification/Notification.js';
import NumberCard from './charts/NumberCard.js';
import TableChart from './charts/TableChart.js';
import VectorMap from './charts/VectorMap.js';
import Map from './charts/Map.js';
import RCMultiLineChart from './charts/RCMultiLineChart.js';
import { MultiSelect } from 'react-multi-select-component';
import SubTitle from './charts/SubTitle.js';
import RCStackedBarChart from './charts/RCStackedBarChart.js';
import RCPercentAreaChart from './charts/RCPercentAreaChart.js';
import { useNavigate } from 'react-router';
import { Box, Slider, TextField, Typography } from '@mui/material';
import AdjustableChart from './charts/AdjustableChart.js';
import { useLocation } from 'react-router-dom';
import RCPieChart from './charts/RCPieChart.js';
import Legend from './charts/Legend.js';
import RCMultipleBarChart from './charts/RCMultipleBarChart.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import Select, { components } from 'react-select';
import MindMap from './charts/MindMap.js';
import { ReactFlowProvider } from 'reactflow';
import { debounce } from 'lodash';
import { FaEdit, FaTrash } from 'react-icons/fa';

const serverConfig = require('./../config/server.js');

const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <div className='custom-option' title={props.label}>
        {props.label}
      </div>
    </components.Option>
  );
};

const Dashboard = () => {
  const [selectedCount, setSelectedCount] = useState(0);
  const [maxCount, setMaxCount] = useState(0);
  const [notInThreshold, setNotInThreshold] = useState(1);

  const MAX = 100;
  const MIN = 0;
  const marks = [
    {
      value: MIN,
      label: '',
    },
    {
      value: MAX,
      label: '',
    },
  ];

  const scaleValue = (value, scaleFactor) => value / scaleFactor;
  const unscaleValue = (value, scaleFactor) => value * scaleFactor;
  const scaleFactor = 1000000;
  const [val, setVal] = useState([
    scaleValue(200, scaleFactor),
    scaleValue(3510540000, scaleFactor),
  ]);

  const handleChange = (item, event, newValue) => {
    const [min, max] = newValue;
    setQuickFiltersMap((prevValues) => ({
      ...prevValues,
      [item?.label]: {
        min: min,
        max: max,
      },
    }));

    let operatorSlug = item?.operators.filter((el) => el?.value === 'between');
    let newData = {
      fltr: item?.fil,
      filter_label: item?.label,
      type: item?.type,
      optr: operatorSlug[0]?.optr,
      filter_operator: operatorSlug[0]?.label,
      array_value: newValue,
      value: null,
    };

    setIsDragging(true);

    setDynamicFilterData((prevState) => {
      const updatedState = Array.isArray(prevState) ? [...prevState] : [];
      const existingIndex = updatedState.findIndex(
        (el) => el.fltr === item?.fil
      );

      if (existingIndex !== -1) {
        updatedState[existingIndex].array_value = newValue;
      } else {
        updatedState.push(newData);
      }
      return updatedState;
    });
  };

  const handleMinInputChange = (event, item) => {
    setSliderInputValueChange(true);
    const minValue = event.target.value;
    if(minValue < quickFiltersMap[item?.label]?.max)
    {
      setQuickFiltersMap((prevValues) => ({
        ...prevValues,
        [item?.label]: {
          min: minValue,
          max: prevValues[item?.label]?.max,
        },
      }));
      debouncedUpdateDynamicFilter(item, minValue, quickFiltersMap[item?.label]?.max, filterData);
    }
    else 
    {
      setQuickFiltersMap((prevValues) => ({
        ...prevValues,
        [item?.label]: {
          min: prevValues[item?.label]?.max,
          max: prevValues[item?.label]?.max,
        },
      }));
      debouncedUpdateDynamicFilter(item, quickFiltersMap[item?.label]?.max, quickFiltersMap[item?.label]?.max, filterData);
    }

  };

  const handleMaxInputChange = (event, item) => {
    setSliderInputValueChange(true);
    const maxValue = event.target.value; 
      setQuickFiltersMap((prevValues) => ({
        ...prevValues,
        [item?.label]: {
          min: prevValues[item?.label]?.min,
          max: maxValue,
        },
      }));
      debouncedUpdateDynamicFilter(item, quickFiltersMap[item?.label]?.min, maxValue, filterData);
    
  };

  const handleFromDateChange = (event, item) => {
    const fromDate = event.target.value;
    setQuickFiltersMap((prevValues) => ({
      ...prevValues,
      [item?.label]: {
        min: fromDate,
        max: prevValues[item?.label]?.max,
      },
    }));
  };

  const handleToDateChange = (event, item) => {
    const toDate = event.target.value;
    setQuickFiltersMap((prevValues) => ({
      ...prevValues,
      [item?.label]: {
        min: prevValues[item?.label]?.min,
        max: toDate,
      },
    }));
  };

  const updateDynamicFilterData = (item, sliderInputValues, currentFilterData) => {
    let newData = {
      fltr: item?.fil,
      filter_label: item?.label,
      type: item?.type,
      optr: item?.operators[0]?.optr,
      filter_operator: item?.operators[0]?.label,
      array_value: sliderInputValues,
      value: null,
    };
  
    setDynamicFilterData((prevState) => {
      const mergedData = [...currentFilterData];
      const updatedState = Array.isArray(prevState) ? [...prevState] : [];
      const existingIndex = updatedState.findIndex((el) => el.fltr === item?.fil);
  
      if (existingIndex !== -1) {
        updatedState[existingIndex].array_value = sliderInputValues;
      } else {
        updatedState.push(newData);
      }
      
      updatedState.forEach((item) => {
        const index = mergedData.findIndex((d) => d.fltr === item.fltr);
        if (index !== -1) {
          mergedData[index] = item;
        } else {
          mergedData.push(item);
        }
      });
  
      handleSubmit(mergedData, null);
      return updatedState;
    });
  
    setSliderInputValueChange(false);
  };
  

  const debouncedUpdateDynamicFilter = useRef(
    debounce((item, minValue, maxValue, currentFilterData) => {
      updateDynamicFilterData(item, [minValue, maxValue], currentFilterData);
    }, 2000)
  ).current;

  const navigate = useNavigate();
  const { collapse } = useContext(Context);
  const { selectedOrganization } = useContext(Context);
  const searchParams = new URLSearchParams(window.location.search);
  const dash = searchParams.get('slug');
  const [dashboardList, setDashboardList] = useState();
  const [dashboardLoader, setDashboardLoader] = useState(false);
  const [token, setToken] = useState(null);
  const [filterLookup, setFilterLookup] = useState([]);
  const [quickFilterLookup, setQuickFilterLookup] = useState([]);
  const [savedFilterLookup, setSavedFilterLookup] = useState([]);
  const [saveFilterValue, setSaveFilterValue] = useState(null);
  const [filterApply, setFilterApply] = useState(false);
  const [filter, setFilter] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [taggedFilterData, setTaggedFilterData] = useState([]);
  const [filterOperator, setFilterOperator] = useState();
  const [value, setValue] = useState();
  const [value2, setValue2] = useState();
  const [nextId, setNextId] = useState(1);
  const [chartData, setChartData] = useState([]);
  const [dashboardTitle, setDashboardTitle] = useState('');
  const [filterName, setFilterName] = useState('');
  const [filterSave, setFilterSave] = useState(false);
  const [filterEdit, setFilterEdit] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [filterDelete, setFilterDelete] = useState(false);
  const [filterEditData, setFilterEditData] = useState({});
  const [lookupShow, setLookupShow] = useState(false);
  const [lookupOptions, setLookupOptions] = useState([]);
  const [lookupValue, setLookupValue] = useState([]);
  const [staticLookupOptions, setStaticLookupOptions] = useState([]);
  const [currPage, setCurrPage] = useState('canvas');
  const [filterClose, setFilterClose] = useState(true);
  const [dynamicFilterData, setDynamicFilterData] = useState([]);
  const [optionsMap, setOptionsMap] = useState({});
  const [originalOptionsMap, setOriginalOptionsMap] = useState({});
  const [quickFiltersMap, setQuickFiltersMap] = useState({});
  const [isDragging, setIsDragging] = useState(false);
  const location = useLocation();
  const [previousSlug, setPreviousSlug] = useState('cnsdjcnsd cjnsdc sdjncs');
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [dropdownsOpen, setDropdownsOpen] = useState({});
  const [sliderInputValueChange, setSliderInputValueChange] = useState(false);
  const [publishToAllDashboards, setPublishToAllDashboards] = useState(false);
  const [publishToAllUsers, setPublishToAllUsers] = useState(false);
  const [isPublishPrivileged, setIsPublishPrivileged] = useState(0);
  const [currentFilterLabel, setCurrentFilterLabel] = useState('');
  const [allItems, setAllItems] = useState({});

  const dropdownRefs = useRef({});

  const currentLabelRef = useRef(null);
  const handleLogout = () => {
    sessionStorage.clear();
    UserService.doLogout();
  };
  let abortController;
  const [alertMessage, setAlertMessage] = useState('');
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const handleCloseAlertModal = () => {
    setAlertMessage('');
    setShowOkButton(false);
    setAlertModalShow(false);
  };
  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  };

  const getToken = () => {
    let jwt = UserService.getToken();
    axios
      .post(serverConfig.api_base_url + 'user_get_token', {
        jwt: jwt,
        url: window.location.host,
      })
      .then((response) => {
        if (response.data.status === 200) {
          sessionStorage.setItem('token', response.data.token);
          setToken(response.data.token);
        } else if (response.data.status >= 400 && response.data.status <= 499) {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.jwt_validation === 'fail') {
          handleLogout();
        }
      })
      .catch((err) => console.log('error is', err));
  };

  useEffect(() => {
    if (sessionStorage.getItem('token') === null) {
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
  }, []);
        
  let mergedFilterData;
  const filterListGet = () => {
    if (JSON.stringify(mergedFilterData) !== JSON.stringify(filterData)) {
    axios
      .post(serverConfig.api_base_url + 'canvas_filter_get_list', {
        token: token,
        org: selectedOrganization?.slug,
        dash: dash,
        filter: filterData,
      })
      .then((response) => {
        if (response.data.status === 200) {
          const staticFilters = response.data.items.filter(
            (el) =>
              el?.is_static === 1  && el?.sequence_no != undefined
          )
          .sort((a, b) => a.sequence_no - b.sequence_no);
          const dynamicFilters = response.data.items.filter(
            (el) => el?.is_static === 0
          );
          const quickFilters = response.data.items.filter(
            (el) =>
              el?.is_static === 1 &&
              (el?.type === 'numeric' || el?.type === 'date')
          );

          const initialState = {};

          quickFilters.forEach((filter) => {
            const operator = filter.operators[0]; // Assuming first operator has min and max

            if (operator.options) {
              initialState[filter.label] = {
                min: operator.options.selected_min,
                max: operator.options.selected_max,
              };
            }
          });
          setQuickFiltersMap(initialState);

          setStaticLookupOptions(staticFilters);
          setFilterLookup(dynamicFilters);
          setQuickFilterLookup(quickFilters);

          if (response.data.pinned_filter?.length > 0) {
            const lookupValues = {};
            const pinnedRecords = {};

            response.data.pinned_filter.forEach((filter) => {
              lookupValues[filter?.filter_label] = filter?.array_value?.map(
                (item) => {
                  return {
                    label: item,
                    value: item,
                  };
                }
              );
            });

            response.data.pinned_filter.forEach((filter) => {
              pinnedRecords[filter?.filter_label] = filter?.array_value?.map(
                (item) => {
                  return {
                    label: item,
                    value: item,
                  };
                }
              );
            });
         
            setLookupValue(lookupValues);
            //setAllItems({...allItems, pinnedRecords});
            mergedFilterData = [...filterData];

            response.data.pinned_filter.forEach((pinnedFilter) => {
              const existingFilterIndex = filterData.findIndex(
                (existingFilter) =>
                  existingFilter.filter_label === pinnedFilter.filter_label
              );

              if (existingFilterIndex !== -1) {
                mergedFilterData[existingFilterIndex] = {
                  ...filterData[existingFilterIndex],
                  ...pinnedFilter,
                  array_value: [
                    ...new Set([
                      ...(filterData[existingFilterIndex].array_value || []),
                      ...(pinnedFilter.array_value || []),
                    ])
                  ], 
                };                
              } else {
                mergedFilterData.push(pinnedFilter);
              }
            });

            handleSubmit(mergedFilterData, null);
          }

          else if(response.data.default_filter?.length > 0 && response?.data?.pinned_filter?.length === 0)
          {
            const lookupValues = {};
            const defaultFilters = {};

            response.data.default_filter.forEach((filter) => {
              lookupValues[filter?.filter_label] = filter?.array_value?.map(
                (item) => {
                  return {
                    label: item,
                    value: item,
                  };
                }
              );
            });

            response.data.default_filter.forEach((filter) => {
              defaultFilters[filter?.filter_label] = filter?.array_value?.map(
                (item) => {
                  return {
                    label: item,
                    value: item,
                  };
                }
              );
            });

            setLookupValue(lookupValues);
            setAllItems(defaultFilters);
            mergedFilterData = [...filterData];

            response.data.default_filter.forEach((defaultFilter) => {
              const existingFilterIndex = filterData.findIndex(
                (existingFilter) =>
                  existingFilter.filter_label === defaultFilter.filter_label
              );

              if (existingFilterIndex !== -1) {
                mergedFilterData[existingFilterIndex] = {
                  ...filterData[existingFilterIndex],
                  ...defaultFilter,
                  array_value: [
                    ...new Set([
                      ...(filterData[existingFilterIndex].array_value || []),
                      ...(defaultFilter.array_value || []),
                    ])
                  ], 
                };                
              } else {
                mergedFilterData.push(defaultFilter);
              }
            });

            handleSubmit(mergedFilterData, null);
          }
          else {
            handleSubmit(filterData, null);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  const handleChangeCommitted = (event, newValue) => {
    setIsDragging(false);
    const mergedData = [...filterData];
    dynamicFilterData?.forEach((item) => {
      const index = mergedData.findIndex((d) => d.fltr === item.fltr);
      if (index !== -1) {
        mergedData[index] = item;
      } else {
        mergedData.push(item);
      }
    });
    handleSubmit(mergedData, null);
  };

  const savedFilterListGet = () => {
    axios
      .post(serverConfig.api_base_url + 'canvas_user_filterset_get_list', {
        token: token,
        org: selectedOrganization?.slug,
        dash: dash,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setSavedFilterLookup(response.data.items);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUnpinFilter = (item) => {
    const filterDataPinnedItem = filterData.find((item1) =>
      taggedFilterData.some((item2) => item1.label === item2.label)
    );
    axios
      .post(serverConfig.api_base_url + 'canvas_user_filter_unpin', {
        token: token,
        org: selectedOrganization?.slug,
        dash: dash,
        pinfltr: item?.pinned_slug,
      })
      .then((response) => {
        if (response.data.status === 200) {
          const updatedFilterData = taggedFilterData.map((value) => {
            if (value.pinned_slug == item?.pinned_slug) {
              value.pinned_slug = null;
              return value;
            } else return value;
          });
          //setLookupValue([]);
          filterData.map((item)=>{
            if(item?.filter_operator === 'LIST')
            {
              setLookupOptions([...lookupOptions, item]);
            }
          })
          //setLookupValue(filterData);
          setTaggedFilterData(updatedFilterData);
          handleShowAlertModal('Filter unpinned');
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status >= 400 && response.data.status <= 499) {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.jwt_validation === 'fail') {
          handleLogout();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePinFilter = (item) => {
    const pinData = [item];
    axios
      .post(serverConfig.api_base_url + 'canvas_user_filter_pin', {
        token: token,
        org: selectedOrganization?.slug,
        dash: dash,
        filter: pinData,
      })
      .then((response) => {
        if (response.data.status === 200) {
          const updatedFilterData = taggedFilterData.map((value) => {
            if (value.filter_label == item?.filter_label) {
              value.pinned_slug = response.data.slug;
              return value;
            } else return value;
          });
          setTaggedFilterData(updatedFilterData);
          handleShowAlertModal('Filter pinned');
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status >= 400 && response.data.status <= 499) {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.jwt_validation === 'fail') {
          handleLogout();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setFilterData([]);
    setQuickFiltersMap({});
    setFilterLookup([]);
    setDynamicFilterData([]);
  }, [dash]);

  useEffect(() => {
    savedFilterListGet();
  }, [token, filterSave, dash]);

  useEffect(() => {
    if (isDragging === false) {
      setChartData([]);
      setFilter();
      setFilterApply(false);
      if (token !== null && token !== undefined) {
        // setDashboardLoader(true);

        axios
          .post(serverConfig.api_base_url + 'canvas_dashboard_get_details', {
            token: token,
            org: selectedOrganization?.slug,
            dash: dash,
          })
          .then((response) => {
            setDashboardList(response.data.items);
            setDashboardTitle(response.data.items.name);
            // setDashboardLoader(false);
          })
          .catch((err) => {
            console.log(err);
          });

        // Clear filterData when the slug changes
        const currentSlug = new URLSearchParams(location.search).get('slug');
        if (previousSlug) {
          if (previousSlug !== currentSlug) {
            setLookupValue({});
            setAllItems({});
            setSaveFilterValue(null);
          }
        }
        setPreviousSlug(currentSlug);
        if (
          token !== null &&
          token !== undefined &&
          dash !== null &&
          dash !== undefined &&
          dashboardTitle !== null &&
          dashboardTitle !== undefined &&
          isDragging === false
        ) {
          filterListGet();
        }
      }
    }
  }, [token, filterData, dynamicFilterData, isDragging]);

  const handleSaveFilter = () => {
    const publishToAllDashboardsValue = publishToAllDashboards ? '1' : '0';
    const publishToAllUsersValue = publishToAllUsers ? '1' : '0';
    
    axios
      .post(serverConfig.api_base_url + 'canvas_save_filterset', {
        token: token,
        org: selectedOrganization?.slug,
        dash: dash,
        filter: filterData,
        filter_name: filterName,
        publish_to_all_dashboards:publishToAllDashboardsValue,
        publish_to_all_users:publishToAllUsersValue,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setFilterSave(false);
          handleShowAlertModal('Filterset saved');
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.status >= 400 && response.data.status <= 499) {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        } else if (response.data.jwt_validation === 'fail') {
          handleLogout();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function stringIsArray(str) {//check whether string is an array or not
    try {
      return new Function(`return Array.isArray(${str})`)();
    } catch {
      return false;
    }
  }

  const convertToArray = (data) => {
    return data?.map((item) => item?.value);
  };

  const controllerRef = useRef(null);

  const handleSubmit = (filter, values) => {

    if (controllerRef.current) {
      controllerRef.current.abort();
    }

    controllerRef.current = new AbortController();
    const controller = controllerRef.current;

    let updatedData = [...filterData];
    
    if (filter && filter?.type === 'lookup' && values) {
      var result = convertToArray(values);

      let fullArr = convertToArray(originalOptionsMap[currentFilterLabel]);
      
      if(filter?.operators[0]?.is_multiselect === 0)
        {
        }
        else if(values?.length < (notInThreshold*maxCount)){
      } else if(values?.length > (notInThreshold*maxCount)){
        result=fullArr?.filter(x => !result.includes(x)).concat(result.filter(x => !fullArr?.includes(x)));
      }

      const existingFilterIndex = updatedData.findIndex(
        (f) => f.filter_label === filter?.label
      );
      if (existingFilterIndex >= 0) {
        updatedData[existingFilterIndex].array_value = result?.length === 0 ? updatedData[existingFilterIndex]?.array_value : result;

        // updatedData1[existingFilterIndex].array_value =
        //   result1 === null
        //     ? updatedData1[existingFilterIndex]?.array_value
        //     : result1;

        //     console.log(updatedData1);

        if(filter?.operators[0]?.is_multiselect === 0)
          {
            //for single select dropdown use IN operator
            filterOptr = filter?.operators.filter(el => el.label === "IN")[0]?.optr;
            filterOperator = filter?.operators.filter(el => el.label === "IN")[0]?.label;
          }
          else if(values?.length < (notInThreshold*maxCount)){
          updatedData[existingFilterIndex].optr = filter?.operators.filter(el => el.label === "IN")[0]?.optr;
          updatedData[existingFilterIndex].filter_operator = filter?.operators.filter(el => el.label === "IN")[0]?.label;
        } else if(values?.length > (notInThreshold * maxCount)){
          updatedData[existingFilterIndex].optr = filter?.operators.filter(el => el.label === "NOT IN")[0]?.optr;
          updatedData[existingFilterIndex].filter_operator = filter?.operators.filter(el => el.label === "NOT IN")[0]?.label;
        }
      } else {
        var filterOptr, filterOperator;
        if(filter?.operators[0]?.is_multiselect === 0)
        {
          //for single select dropdown use IN operator
          filterOptr = filter?.operators.filter(el => el.label === "IN")[0]?.optr;
          filterOperator = filter?.operators.filter(el => el.label === "IN")[0]?.label;
        }
        else if(values?.length < (notInThreshold*maxCount)){
          //use IN operator
          filterOptr = filter?.operators.filter(el => el.label === "IN")[0]?.optr;
          filterOperator = filter?.operators.filter(el => el.label === "IN")[0]?.label;
        } else if(values?.length > (notInThreshold*maxCount)){
          //use NOT IN operator
          filterOptr = filter?.operators.filter(el => el.label === "NOT IN")[0]?.optr;
          filterOperator = filter?.operators.filter(el => el.label === "NOT IN")[0]?.label;
        }
        const newFilter = {
          id: nextId,
          filter_name: filter?.operators?.value,
          filter_label: filter?.label,
          optr: filterOptr,
          filter_operator: filterOperator,
          array_value: result,
          fltr: filter?.fil,
          value: null,
        };
        updatedData.push(newFilter);
      }
      setFilterData(updatedData);
      setTaggedFilterData(updatedData);

    } else if (filter?.type === 'numeric' || filter?.type === 'text') {
      updatedData.push(filter);
      if (JSON.stringify(filterData) !== JSON.stringify(updatedData)) {
        setFilterData(updatedData);
        setTaggedFilterData(updatedData);
      }

    } else if (filter?.type === 'data_limit') {
      // Handle 'data_limit' type
    console.log('Handling data_limit type');
    const dataLimitValue = values?.data_limit || 0; // Assuming the `values` object contains a `data_limit` property

    // Check if a filter with the same label already exists
    const existingFilterIndex = updatedData.findIndex((el) => el.filter_label === filter?.label);

    if (existingFilterIndex >= 0) {
        // Update the existing filter
        updatedData[existingFilterIndex] = {
            ...updatedData[existingFilterIndex],
            value: dataLimitValue, // Update the value
        };
    } else {
        // Add a new filter if it doesn't exist
        const newFilter = {
            id: nextId,
            filter_name: filter?.label,
            filter_label: filter?.label,
            array_value: [], // No array values for data_limit
            fltr: filter?.fil,
            value: dataLimitValue,
        };
        updatedData.push(newFilter);
    }

    // Update the state
    setFilterData(updatedData);
    setTaggedFilterData(updatedData);
    } else {
      updatedData = filter;
      let selectedValues = {};
      updatedData?.forEach((filter) => {
        selectedValues[filter?.filter_label] = filter?.array_value?.map((item) => {
              return {
                label: item,
                value: item,
              };
            });
          });
      if (JSON.stringify(filterData) !== JSON.stringify(updatedData)) {
        setFilterData(updatedData);
        setTaggedFilterData(updatedData);
        setAllItems(selectedValues);
      }     
      //setAllItems(selectedValues);
    }

    const lookupValues = {};
    const lookupValues1 = {};

    if((values?.length - 1) < (notInThreshold*maxCount)){
      updatedData?.forEach((filter) => {
        lookupValues[filter?.filter_label] = filter?.array_value?.map((item) => {
          return {
            label: item,
            value: item,
          };
        });
      });
      setLookupValue(lookupValues);
      //setAllItems(lookupValues);
    } else if((values?.length - 1) > (notInThreshold*maxCount)){
      updatedData?.forEach((filter) => {
        lookupValues[filter?.filter_label] = filter?.array_value?.map((item) => {
          return {
            label: item,
            value: item,
          };
        });
      });
      //setAllItems(lookupValues);
      // updatedData1?.forEach((filter) => {
      //   lookupValues1[filter?.filter_label] = filter?.array_value?.map((item) => {
      //     return {
      //       label: item,
      //       value: item,
      //     };
      //   });
      // });
      // console.log(lookupValues1);
      // setLookupValue(lookupValues1);
    }

    let selectedValues = {};
      updatedData?.forEach((filter) => {
        selectedValues[filter?.filter_label] = filter?.array_value?.map((item) => {
          return {
            label: item,
            value: stringIsArray(item) ? JSON.parse(item) : item,
          };
        });
        
        filter.array_value = stringIsArray(filter.array_value[0]) ? JSON.parse(filter.array_value[0]) : filter.array_value;
      });
      //setAllItems(selectedValues);

      axios
      .post(serverConfig.api_base_url + 'canvas_dataset_get_list', {
        token: sessionStorage.getItem('token'),
        org: selectedOrganization?.slug,
        dash: searchParams.get('slug'),
        filter: updatedData,
      },
      { signal: controller.signal })
      .then((response) => {
        if (response.data.items !== null) {
          setChartData(response?.data?.items);
        }
        if (response.data.status === 200) {
          if (response.data.items !== null) {
            response.data.items?.map((item) => {
              axios
                .post(item?.url, {
                  fetch_instruction: item?.fetch_instruction,
                },  { signal: controller.signal })
                .then((res) => {
                  const updatedData = res.data.items;
                  setChartData((prevData) => {
                    const existingItemIndex = prevData?.findIndex(
                      (dataItem) => dataItem?.cht === item?.cht
                    );
                    return existingItemIndex !== -1
                      ? prevData?.map((dataItem, index) =>
                          index === existingItemIndex ? updatedData : dataItem
                        )
                      : prevData;
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            });
          }
        } else if (response.data.status >= 400 && response.data.status <= 499) {
        }
        setFilterApply(false);
      })
      .catch((err) => {
        if (controller.signal.aborted) {
          console.log("Request aborted");
        } else {
          console.log(err);
        }
      });
    setNextId(nextId + 1);
  };


  const handleRemoveFilter = (fltr) => {
    setChartData([]);
    const updatedData = filterData.filter((el) => el?.fltr !== fltr);
    const updatedDynamicFilterData = filterData.filter((el) => el?.fltr !== fltr);
    setSaveFilterValue(null);
    const lookupValues = {};

    updatedData?.forEach((filter) => {
      lookupValues[filter?.filter_label] = filter?.array_value?.map((item) => {
        return {
          label: item,
          value: item,
        };
      });
    });
    setDynamicFilterData(updatedDynamicFilterData);
    setLookupValue(lookupValues);
    setAllItems(lookupValues);
    setFilterData(updatedData);
    setTaggedFilterData(updatedData);
    axios
      .post(serverConfig.api_base_url + 'canvas_dataset_get_list', {
        token: token,
        org: selectedOrganization?.slug,
        dash: dash,
        filter: updatedData,
      })
      .then((response) => {
        setChartData(response.data.items);
        if (response.data.status === 200) {
          response.data.items?.map((item) => {
            axios
              .post(item?.url, {
                fetch_instruction: item?.fetch_instruction,
              })
              .then((res) => {
                const updatedData = res.data.items;
                setChartData((prevData) => {
                  const existingItemIndex = prevData?.findIndex(
                    (dataItem) => dataItem?.cht === item?.cht
                  );
                  return existingItemIndex !== -1
                    ? prevData.map((dataItem, index) =>
                        index === existingItemIndex ? updatedData : dataItem
                      )
                    : prevData;
                });
                setValue('');
                setFilterOperator();
              })
              .catch((err) => {
                console.log(err);
              })
              .finally((fun) => {});
          });
        } else if (response.data.status >= 400 && response.data.status <= 499) {
          handleShowAlertModal(response.data.message, true);
          setTimeout(() => {
            handleCloseAlertModal();
          }, 6000);
        }
        setFilterApply(false);
      })
      .catch((err) => {
        console.log(err);
      });
    setNextId(nextId + 1);
  };

  const handleCallDBFetcher = (url, payload) => {
    axios
      .post(url, {
        fetch_instruction: payload,
      })
      .then((response) => {
        setLookupOptions(response.data.items);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCallDBFetcherDynamic = async (url, payload, label) => {
    try {
      if (currentLabelRef.current !== label) {
        setLoadingOptions(true);
        currentLabelRef.current = label;
      }

      const response = await axios.post(url, {
        fetch_instruction: payload,
      });

      const formattedOptions = response.data.items?.map((item) => ({
        ...item,
        label: item.label.toString(),
        value: item.value,
      }));

      setOptionsMap((prevOptionsMap) => ({
        ...prevOptionsMap,
        [label]: formattedOptions,
      }));

      if(formattedOptions?.length > 0)
      {
        setOriginalOptionsMap((prevOptionsMap) => {
          const existingOptions = prevOptionsMap[label] || [];
          const mergedOptions = [
            ...existingOptions,
            ...formattedOptions?.filter(
              (newOption) => !existingOptions.some((existingOption) => existingOption.value === newOption.value)
            ),
          ];
          return {
            ...prevOptionsMap,
            [label]: mergedOptions,
          };
        });
      }
      setCurrentFilterLabel(label);
      const filterExists = filterData?.some(el => el?.filter_label === label) ? 1 : 0;
      if(!filterExists)
      {
        if(allItems[label] === undefined)
        {
          setAllItems({...allItems, [label]: formattedOptions});
        }
      }
     

      setLoadingOptions(false);
    } catch (error) {
      setOptionsMap((prevOptionsMap) => ({
        ...prevOptionsMap,
        [label]: [],
      }));
      setLoadingOptions(false);
    }
  };

  useEffect(() => {
    if (saveFilterValue !== null && token !== null && token !== undefined) {
      axios
        .post(serverConfig.api_base_url + 'canvas_user_filterset_get_value', {
          token: token,
          org: selectedOrganization?.slug,
          dash: dash,
          sfltr: saveFilterValue?.sfltr,
        })
        .then((response) => {
          setFilterData(response?.data?.items);
          setTaggedFilterData(response?.data?.items);
          const lookupValues = {};
          response?.data?.items.forEach((filter) => {
            lookupValues[filter.filter_label] = filter?.array_value?.map(
              (item) => {
                return {
                  label: item,
                  value: item,
                };
              }
            );
          });
          setLookupValue(lookupValues);
          handleSubmit(response?.data?.items, null);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [saveFilterValue, token]);

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '315px',
      cursor: 'pointer',
    pointerEvents: 'auto',
    }),
  };

  const sortOptions = (options, selectedOptions) => {
    const selectedValues = selectedOptions.map((opt) => opt.value);
    return [...options].sort((a, b) => {
      const isSelectedA = selectedValues.includes(a.value);
      const isSelectedB = selectedValues.includes(b.value);
      return isSelectedB - isSelectedA; // Move selected options to the top
    });
  };

  const handleDropdownChange = (label, e, item) => {
  
    setCurrentFilterLabel(label);
    setMaxCount(item.max_count);
    setNotInThreshold((item.not_in_threshold / 100));

    const originalOptions = originalOptionsMap[label] || []; // Use the unfiltered original options
    if (
      originalOptions.length > 0 &&
      (originalOptions.length === e.length || e.length === 0) // Check against the original options
    ) {
      if (originalOptions.length === e.length) {
        // Select All
        setFilterData((prevFilterData) =>
          prevFilterData.filter((filter) => filter.filter_label !== label)
        );
        setTaggedFilterData((prevFilterData) =>
          prevFilterData.filter((filter) => filter.filter_label !== label)
        );
        setAllItems({ ...allItems, [label]: originalOptions });
        setSelectedCount(item.max_count);
      } else if (e.length === 0) {
        // Unselect All
        setFilterData((prevFilterData) =>
          prevFilterData.filter((filter) => filter.filter_label !== label)
        );
        setTaggedFilterData((prevFilterData) =>
          prevFilterData.filter((filter) => filter.filter_label !== label)
        );
        setAllItems({ ...allItems, [label]: [] });
        setLookupValue((prevValues) => ({
          ...prevValues,
          [label]: [],
        }));
        setSelectedCount(0);
        const selectedFilter = {
          filter_name: item?.operators[0]?.value,
          filter_label: item?.label,
          optr: item?.operators[0]?.optr,
          filter_operator: item?.operators[0]?.label,
          array_value: [],
          fltr: item?.fil,
          value: null,
        };
        const mergedFilterData = filterData.filter((el) => el?.fltr !== item?.fil);;
        mergedFilterData.push(selectedFilter);
        handleFetchDataDebounce(mergedFilterData, null);
      }
    } else if (originalOptions.length < e.length) {
      // Selected options exceed original length
      setFilterData((prevFilterData) =>
        prevFilterData.filter((filter) => filter.filter_label !== label)
      );
      setTaggedFilterData((prevFilterData) =>
        prevFilterData.filter((filter) => filter.filter_label !== label)
      );
      setAllItems({ ...allItems, [label]: e });
      setSelectedCount(item.max_count);
    } else {
      // Handle partial selections
      setSelectedCount(e.length);
      setAllItems({ ...allItems, [label]: e });
  
      if (e.length < notInThreshold * item.max_count) {

      } else if (e.length > notInThreshold * item.max_count) {
   
      }
  
      if (e.length === 0) {
        const updatedArray = filterData.filter((el) => el?.fltr !== item?.fil);
        setFilterData((prev) => prev.filter((el) => el?.fltr !== item?.fil));
        setTaggedFilterData((prev) => prev.filter((el) => el?.fltr !== item?.fil));
        setLookupValue((prevValues) => ({
          ...prevValues,
          [label]: e,
        }));
        handleFetchDataDebounce(updatedArray, null);
      } else {
        setLookupValue((prevValues) => ({
          ...prevValues,
          [label]: e,
        }));
        handleFetchDataDebounce(item, e);
      }
    }
  };
  

  const handleDropdownChangeSingleSelect = (label, e, item) => {
    let records = [e];
    if (records?.length === 0) {
      const updatedArray = filterData.filter((el) => el?.fltr !== item?.fil);
      setFilterData(updatedArray);
      setTaggedFilterData(updatedArray);
      handleSubmit(updatedArray, null);
    } else {
      setLookupValue((prevValues) => ({
        ...prevValues,
        [label]: records,
      }));
      handleSubmit(item, records);
    }

  };

  const handleDropdownChangeSingleSelectUpdate = (label, e, item) => {
    // Extract the selected value as records
    let records = [e];
    console.log(records, "records");

    if (!e || records?.length === 0) {
        // If no value is selected, remove the filter
        const updatedArray = filterData.filter((el) => el?.filter_label !== label);
        setFilterData(updatedArray);
        setTaggedFilterData(updatedArray);
        handleSubmit(updatedArray, null);
        return;
    }

    // Extract the actual value from the selected record
    const selectedValue = e?.value;

    if (selectedValue === undefined) {
        console.error("Selected value is undefined");
        return;
    }

    // Check if the filter already exists in filterData
    const existingFilterIndex = filterData.findIndex((el) => el?.filter_label === label);

    // Clone filterData to avoid mutating state directly
    let updatedFilterData = [...filterData];

    if (existingFilterIndex >= 0) {
        // Update the existing filter with the new value
        updatedFilterData[existingFilterIndex] = {
            ...updatedFilterData[existingFilterIndex],
            array_value: [selectedValue], // Replace with the new selected value
        };
    } else {
        // Add a new filter if it doesn't exist
        updatedFilterData.push({
            id: item?.id || Math.random(), // Use item's ID or generate a random one
            filter_label: label,
            array_value: [selectedValue], // Set the selected value
            fltr: item?.fil,
            value: null,
        });
    }

    // Update the state and call handleSubmit
    setFilterData(updatedFilterData);
    setTaggedFilterData(updatedFilterData);
    setLookupValue((prevValues) => ({
        ...prevValues,
        [label]: records,
    }));

    // Pass the selected value to handleSubmit
    handleSubmit(item, { data_limit: selectedValue });
};


  useEffect(() => {
    function handleClickOutside(event) {
      for (let key in dropdownRefs.current) {
        if (
          dropdownRefs.current[key] &&
          !dropdownRefs.current[key].contains(event.target)
        ) {
          setDropdownsOpen((prev) => ({ ...prev, [key]: false })); // Close specific dropdown
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRefs]);

  const handleDropdownClick = (label) => {
    setDropdownsOpen((prevState) => ({
      ...prevState,
      [label]: true,
    }));
  };

  const loadingOptionShow = [
    { label: 'Loading...', value: 'loading', disabled: true },
  ];

  const [hoveredOption, setHoveredOption] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const lastSearchTermRef = useRef('');

  // const debouncedFetchAdditionalOptions = useRef(
  //   debounce((searchTerm, item, token, time) => {
  //     fetchAdditionalOptions(searchTerm, item, token);
  //   }, time * 1000)
  // ).current;

  const getDebouncedFunctionHandleSubmit = () => {
    return debounce((filterObj, entries) => {
      handleSubmit(filterObj, entries);
    }, 2000);
  };

  const getDebouncedFunction = (time) => {
    return debounce((searchTerm, item, token) => {
      fetchAdditionalOptions(searchTerm, item, token);
    }, time * 1000);
  };
  
  const handleFetchWithDebounce = (searchTerm, item, token, time) => {
    const debouncedFunction = getDebouncedFunction(time);
    debouncedFunction(searchTerm, item, token);
  };

  const handleFetchDataDebounce = (filterObj, entries) => {
    const debouncedFunction = getDebouncedFunctionHandleSubmit();
    debouncedFunction(filterObj, entries);
  }

  const fetchAdditionalOptions = (searchTerm, item, userToken) => {
    const filter = {
      filter_label: item?.label,
      fltr: item?.fil,
      optr: item?.filter_search_operator[0]?.optr,
      type: item?.type,
      filter_operator: item?.filter_search_operator[0]?.label,
      value: searchTerm
    }

    const updatedFilterData = [...filterData, filter];
    
    axios.post(serverConfig.api_base_url + "canvas_search_filter_get_list", {
      dash: dash,
      org: selectedOrganization?.slug,
      token: userToken,
      filter: updatedFilterData
    }).then(response=> {

      response.data.items.map(record => {
          if(record?.fil === item.fil)
          {
            handleCallDBFetcherDynamic(
              record.operators[0]?.url,
              record.operators[0]
                ?.fetch_instruction,
              record.label
            );
          }
      })
      
    }).catch(err=>{
      console.log(err);
    })
  }

  const CustomSingleValue = (props) => {
    const { data, selectProps } = props;
    const itemLabel = selectProps.customLabel;
  
    return (
      <components.SingleValue {...props}>
        {`${itemLabel} : ${data.label}`}
      </components.SingleValue>
    );
  };

  const CustomOption = (props) => {
    // Log the props to inspect what is being passe
  
    const handleEditClick = (event) => {
      event.stopPropagation();
      props.onEdit(props.data); // Use the passed `onEdit` function
    };
  
    const handleDeleteClick = (event) => {
      event.stopPropagation();
      props.onDelete(props.data); // Use the passed `onDelete` function
    };
  
    const toggleMenu = (event) => {
      event.stopPropagation();
      setShowMenu(!showMenu); // Assuming `setShowMenu` is defined elsewhere
    };
  
    return (
      <components.Option {...props}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center', 
            gap: '3px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: '20px',
            }}
          >
            {props.data.publish_to_all_dashboards === 1 && (
              <i className="fa-solid fa-thumbtack" 
              style={{ fontSize: '10px', 
                marginRight: '3px', 
                color:'#b5b5b5' }}
                title="Published to all dashboards"></i>
            )}
            {props.data.publish_to_all_users === 1 && (
              <i className="fa-solid fa-user-group" 
              style={{ fontSize: '10px', color:'#b5b5b5'}}
              title="Published to all users"></i>
            )}
          </div>
          <span
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '250px',
              textAlign: 'left',
              marginLeft:'2px', 
            }}
            title={props.label}
          >
            {props.label}
          </span>
        </div>
        {props.data.allow_edit_delete === 1 && (
          <div className="flex">
            <div
              style={{ marginRight: '10px', cursor: 'pointer', fontSize: '12px', color:'#828282' }}
              onClick={handleEditClick}
              title='Edit Filter'
            >
              <i className="fa-solid fa-pencil"></i>
            </div>
            <div
              style={{ cursor: 'pointer', fontSize: '12px', color:'#828282' }}
              onClick={handleDeleteClick}
              title='Delete Filter'
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </div>
          </div>
        )}
      </div>
    </components.Option>
    );
  };  
     
  const handleEdit = (data) => { 
    // Implement the edit functionality here 
    setFilterEditData(data);
    setFilterName(data.label);
    setPublishToAllDashboards(data.publish_to_all_dashboards === 1); 
    setPublishToAllUsers(data.publish_to_all_users === 1);

    axios
      .post(serverConfig.api_base_url + 'user_check_privilege', {
            token: token,
            org: selectedOrganization?.slug,
            privilege: 'filter_publish_all_user',
      })
      .then((response) => {
            if (response.data.status === 200) {
              setIsPublishPrivileged(response.data.is_privileged);
            } ;
      })
      .catch((err) => {
            console.log(err);
      });
    setFilterEdit(true); 
  }; 

  const handleSavedFilterUpdate = () =>{
    let publish_to_all_dashboards = publishToAllDashboards === true ? 1 : 0;
    let publish_to_all_users = publishToAllUsers === true ? 1 : 0;
    axios
      .post(serverConfig.api_base_url + 'canvas_update_filterset', {
            token: token,
            org: selectedOrganization?.slug,
            dash: dash,
            fltrst: filterEditData.sfltr,
            publish_to_all_users: publish_to_all_users,
            publish_to_all_dashboards: publish_to_all_dashboards,
            filter_name: filterName,
      })
      .then((response) => {
            if (response.data.status === 200) {
              savedFilterListGet();
              setFilterName('');
              setFilterEdit(false);
              setIsPublishPrivileged(0);
              setPublishToAllDashboards(false);
              setPublishToAllUsers(false);
              setFilterEditData({});
            };
      })
      .catch((err) => {
            console.log(err);
      });
    
  }
  
  const handleDelete = (data) => { 
    // Implement the delete functionality here 
    setFilterName(data.label);
    setFilterEditData(data); 
    setFilterDelete(true);
  };

  const handleSavedFilterDelete = () =>{
    axios
      .post(serverConfig.api_base_url + 'canvas_delete_filterset', {
            token: token,
            org: selectedOrganization?.slug,
            dash: dash,
            fltrst: filterEditData.sfltr,
      })
      .then((response) => {
            if (response.data.status === 200) {
              savedFilterListGet();
              setFilterName('');
              setFilterDelete(false);
              setFilterEditData({});
            };
      })
      .catch((err) => {
            console.log(err);
      });
  }

  return (
    <div>
      <div>
        <div className={collapse ? 'content-body' : 'content-body-collapse'}>
          <div class='container-fluid'>
            <div className=''>
              {/* -- */}
              {!dashboardLoader ? ( 
                dashboardList !== null ? (
                <div
                  className={`${
                    filterClose
                      ? `table-custom-dashboard`
                      : `table-custom-dashboard-closed`
                  } p-3 mt-4 flex flex-row`}
                  style={{ overflow: 'hidden' }}
                >
                  <div style={filterClose ? {} : { width: '106%' }}>
                    {' '}
                    {/* Width gap for filter button when closed */}
                    <div className='flex flex-row justify-between'>
                      <div
                        className='dashboard-heading'
                        style={{ textAlign: 'left', margin: '10px' }}
                      >
                        {dashboardTitle}
                      </div>
                    </div>
                    <div
                      className='flex flex-row flex-wrap'
                      style={{ gap: '5px' }}
                    >
                      {filterData?.length > 0 ? (
                        <>
                          {taggedFilterData.map((item, index) => {
                            return (
                              <div
                                className={`flex flex-wrap ml-4 mt-2`}
                                style={{ maxWidth: '100%' }}
                              >
                                {item?.filter_operator !== 'Between' &&
                                (item?.value !== null ||
                                  item?.array_value?.length > 0) ? (
                                  <div
                                    className='status mx-1 relative'
                                    style={{
                                      backgroundColor: `var(--primary-opactiy-30)`,
                                      borderColor: `var(--primary-opactiy-90)`,
                                      maxWidth: '350px',
                                      position: 'relative',
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                    title={`${item?.filter_label} ${
                                      item?.filter_operator
                                    } : ${
                                      item?.value === null
                                        ? item?.array_value?.join(', ')
                                        : item?.value
                                    }`}
                                  >
                                    {item?.pinned_slug ? (
                                      <></>
                                    ) : (
                                      <button
                                        type='button'
                                        className='btn-remove'
                                        title='Remove'
                                        onClick={() => {
                                          handleRemoveFilter(item?.fltr);
                                        }}
                                      >
                                        <i className='fa-solid fa-xmark '></i>
                                      </button>
                                    )}

                                    <span
                                      style={{
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        flexGrow: 1,
                                        marginLeft: '5px',
                                      }}
                                    >
                                      {item?.filter_label}{' '}
                                      {item?.filter_operator} :{' '}
                                      {item?.value === null
                                        ? item?.array_value?.join(', ')
                                        : item?.value}
                                    </span>

                                    <button
                                      type='button'
                                      className='pin-btn'
                                      title='Pin'
                                      style={{
                                        color: item?.pinned_slug
                                          ? 'black'
                                          : 'gray',
                                        transform: item?.pinned_slug
                                          ? 'rotate(0deg)'
                                          : 'rotate(60deg)',
                                      }}
                                      onClick={() => {
                                        {
                                          !item?.pinned_slug
                                            ? handlePinFilter(item)
                                            : handleUnpinFilter(item);
                                        }
                                      }}
                                    >
                                      <i className='fa-solid fa-thumbtack'></i>
                                    </button>
                                  </div>
                                ) : item?.value !== null ||
                                  item?.array_value?.length > 0 ? (
                                  <div
                                    className='status mx-1 relative'
                                    style={{
                                      backgroundColor: `var(--primary-opactiy-30)`,
                                      borderColor: `var(--primary-opactiy-90)`,
                                      maxWidth: '350px',
                                      position: 'relative',
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                    title={`${item?.filter_label} ${
                                      item?.filter_operator
                                    } : ${
                                      item?.value === null
                                        ? item?.array_value?.join(', ')
                                        : item?.value
                                    }`}
                                  >
                                    {item?.pinned_slug ? (
                                      <></>
                                    ) : (
                                      <button
                                        type='button'
                                        className='btn-remove'
                                        title='Remove'
                                        onClick={() => {
                                          handleRemoveFilter(item?.fltr);
                                        }}
                                      >
                                        <i className='fa-solid fa-xmark '></i>
                                      </button>
                                    )}
                                    <span
                                      style={{
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        flexGrow: 1,
                                        marginLeft: '5px',
                                      }}
                                    >
                                      {item?.filter_label}{' '}
                                      {item?.filter_operator} :{' '}
                                      {`${item?.array_value[0]} and ${item?.array_value[1]}`}
                                    </span>

                                    <button
                                      type='button'
                                      className='pin-btn'
                                      title='Pin'
                                      style={{
                                        color: item?.pinned_slug
                                          ? 'black'
                                          : 'gray',
                                        transform: item?.pinned_slug
                                          ? 'rotate(0deg)'
                                          : 'rotate(60deg)',
                                      }}
                                      onClick={() => {
                                        {
                                          !item?.pinned_slug
                                            ? handlePinFilter(item)
                                            : handleUnpinFilter(item);
                                        }
                                      }}
                                    >
                                      <i className='fa-solid fa-thumbtack'></i>
                                    </button>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div
                      className='grid-container overflow-y-scroll overflow-x-scroll'
                      style={{ height: '81vh', marginRight: '18px' }}
                    >
                      {chartData?.map((item) => {
                        return (
                          <>
                            <div
                              className='grid-item'
                              style={{
                                padding: '0px',
                                fontSize: '30px',
                                //height: "500px",
                                textAlign: 'center',
                                gridRow: `${item?.grid_row}`,
                                gridColumn: `${item?.grid_column}`,
                              }}
                            >
                              {item?.type === 'bar' ? (
                                <AdjustableChart
                                  filterData={filterData}
                                  handleSubmit={handleSubmit}
                                  apiData={item}
                                  item={item}
                                  setOpenDeleteChartModal={''}
                                  setOpenEditChartModal={''}
                                  currPage={currPage}
                                  defaultType='bar'
                                />
                              ) : item?.type === 'line' ? (
                                <AdjustableChart
                                  apiData={item}
                                  item={item}
                                  setOpenDeleteChartModal={''}
                                  setOpenEditChartModal={''}
                                  currPage={currPage}
                                  defaultType='line'
                                />
                              ) : item?.type === 'pie' ? (
                                <RCPieChart
                                  filterData={filterData}
                                  handleSubmit={handleSubmit}
                                  item={item}
                                  apiData={item}
                                  currPage={currPage}
                                  setOpenDeleteChartModal={''}
                                  setOpenEditChartModal={''}
                                />
                              ) : item?.type === 'number-1' ? (
                                <NumberCard
                                  item={item}
                                  data={item?.data}
                                  currPage={currPage}
                                />
                              ) : item?.type === 'table' ? (
                                <TableChart
                                  data={item?.data}
                                  item={item}
                                  currPage={currPage}
                                />
                              ) : item?.type === 'vector_map' ? (
                                <VectorMap
                                  data={item?.data}
                                  item={item}
                                  currPage={currPage}
                                />
                              ) : item?.type === 'map' ? (
                                <Map
                                  data={item?.data}
                                  item={item}
                                  currPage={currPage}
                                />
                              ) : item?.type === 'multiple_bar' ? (
                                <RCMultipleBarChart
                                  apiData={item}
                                  item={item}
                                  currPage={currPage}
                                />
                              ) : item?.type === 'multiple_line' ? (
                                <RCMultiLineChart
                                  apiData={item}
                                  item={item}
                                  currPage={currPage}
                                />
                              ) : item?.type === 'stacked_bar' ? (
                                <RCStackedBarChart
                                  apiData={item}
                                  item={item}
                                  currPage={currPage}
                                />
                              ) : item?.type === 'percent_area' ? (
                                <RCPercentAreaChart
                                  apiData={item}
                                  item={item}
                                  currPage={currPage}
                                />
                              ) : item?.type === 'sub_title' ? (
                                <SubTitle item={item} currPage={currPage} />
                              ) : item?.type === 'legend' ? (
                                <Legend item={item} currPage={currPage} />
                              ) : item?.type === 'mind_map' ? (
                                <ReactFlowProvider>
                                  <MindMap
                                    apiData={item}
                                    item={item}
                                    currPage={currPage}
                                  />
                                </ReactFlowProvider>
                              ) : //put conditions for other types of charts
                              null}
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>

                  {/* filters side bar */}
                  {filterClose === true ? (
                    <div className='grid'>
                      {/* Main content */}
                      <div
                        className={`filter-content ${
                          filterClose ? 'open' : ''
                        } `}
                      >
                        <div className='content-view-filters'>
                          <div className='flex flex-row justify-between items-center gap-1'>
                            <h1 className='font-bold text-lg ml-4 mb-2'>
                              Filters
                            </h1>
                            <div className='flex-grow'></div>
                            {/* This will take up the remaining space */}
                            {savedFilterLookup?.length > 0 && (
                              <div className='mt-1 mb-2 ml-1'>
                                <Select 
                                  styles={{ 
                                    control: (provided) => ({ 
                                      ...provided, 
                                      width: '190px', 
                                      }), 
                                      menu: (provided) => ({ 
                                        ...provided, 
                                        width: '350px',
                                        left: '-80px', 
                                      }), 
                                    }} 
                                  components={{ 
                                      Option: (props) => <CustomOption {...props} onEdit={handleEdit} onDelete={handleDelete} /> 
                                    }} 
                                  classNamePrefix='custom-select' 
                                  placeholder='Saved Filters' 
                                  value={saveFilterValue} 
                                  options={savedFilterLookup} 
                                  onChange={(e) => { setSaveFilterValue(e); }} 
                                  required
                                />
                              </div>
                            )}
                            <div className='flex justify-center gap-2 mb-1'>
                              <button
                                type='submit'
                                className='ml-2 mt-1'
                                onClick={() => {
                                  setFilterSave(true);
                                  axios
                                    .post(serverConfig.api_base_url + 'user_check_privilege', {
                                      token: token,
                                      org: selectedOrganization?.slug,
                                      privilege: 'filter_publish_all_user',
                                    })
                                    .then((response) => {
                                      if (response.data.status === 200) {
                                        setIsPublishPrivileged(response.data.is_privileged);
                                      } ;
                                    })
                                    .catch((err) => {
                                      console.log(err);
                                    });
                                }}
                                disabled={filterData?.length > 0 ? false : true}
                                title='Save Filters'
                              >
                                <FontAwesomeIcon
                                  icon={faSave}
                                  style={{ fontSize: '26px' }}
                                />
                              </button>
                            </div>
                            <div className='flex justify-center gap-2 mb-1'>
                              <button
                                type='submit'
                                className='ml-2 mt-1'
                                onClick={() => {
                                  setFilterData([]);
                                  setTaggedFilterData([]);
                                  setLookupValue([]);
                                  setSaveFilterValue(null);
                                  setDynamicFilterData([]);
                                  setAllItems({});
                                  setOriginalOptionsMap({});
                                  setOptionsMap({});
                                }}
                                disabled={filterData?.length > 0 ? false : true}
                                title='Reset Filters'
                              >
                                <FontAwesomeIcon
                                  icon={faUndo}
                                  style={{ fontSize: '24px' }}
                                />
                              </button>
                            </div>
                          </div>

                          <button
                            className='button-toggle-filter'
                            onClick={() => {
                              setFilterClose(false);
                            }}
                          >
                            <div
                              className='rounded-full circle bg-white h-5 w-5'
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              {/* {collapse? */}
                              <div>
                                <button
                                  type='button'
                                  class='cancel-btn-popup px-4 py-2 rounded-lg text-sm text-medium'
                                  onClick={() => {}}
                                  title='filter data for dashboard'
                                  style={{ margin: '10px' }}
                                >
                                  <div className='flex flex-col'>
                                    <i className='fa-solid fa-filter'></i>
                                    <i class='fa-solid fa-arrow-right text-xs'></i>
                                  </div>
                                </button>
                              </div>
                              {/* <i class="fa-solid fa-arrow-left text-xs"></i> */}
                              {/* :   <i class="fa-solid fa-arrow-right text-xs"></i>} */}
                            </div>
                          </button>
                          <div className='filter-top-container-bottom-border'></div>

                          <div
                            className='log-section'
                            style={{ height: '78vh', overflowY: 'auto' }}
                          >
                            {staticLookupOptions?.length > 0 &&
                              staticLookupOptions?.map((item, index) => {
                                return (
                                  <>
                                    {item?.type === 'lookup' && item?.operators[0]?.is_multiselect === 1 ? (
                                      <div
                                        key={index}
                                        className='mt-4 mb-2 ml-4 mr-24'
                                        ref={(el) =>
                                          (dropdownRefs.current[item.label] =
                                            el)
                                        } // Use refs for each dropdown
                                        onClick={() => {
                                          handleCallDBFetcherDynamic(
                                            item.operators[0]?.url,
                                            item.operators[0]
                                              ?.fetch_instruction,
                                            item.label
                                          );
                                          setSelectedCount(item.max_count);
                                          handleDropdownClick(item.label);
                                          setLookupValue((prevValues) => ({
                                            ...prevValues,
                                            [item?.label]: [],
                                          }));
                                        }}
                                      >
                                        <MultiSelect
                                          closeMenuOnSelect={false}
                                          hasSelectAll={true}
                                          //value={lookupValue[item.label] || allItems[item.label] || []}
                                          // value={(allItems && optionsMap && allItems[item.label]?.length > 0 && optionsMap[item.label]?.length > 0 && allItems[item.label]?.length === optionsMap[item.label]?.length) ? allItems[item.label] : lookupValue[item.label] || []}
                                          value={(allItems  && allItems[item.label]?.length > 0) ? allItems[item.label] : lookupValue[item.label] || []}
                                          options={
                                            loadingOptions
                                              ? loadingOptionShow
                                              : sortOptions(optionsMap[item.label] || [], allItems[item.label] || [])
                                          }
                                          // options={
                                          //   loadingOptions
                                          //     ? loadingOptionShow
                                          //     : optionsMap[item.label] || []
                                          // }
                                          onChange={(e) => {
                                            handleDropdownChange(
                                              item?.label,
                                              e,
                                              item
                                            );
                                          }}
                                          isOpen={
                                            dropdownsOpen[item.label] || false
                                          }
                                          filterOptions={(options, searchTerm) => {
                                            
                                            if (searchTerm.length === item?.search_char_length && searchTerm !== lastSearchTermRef.current) {
                                              fetchAdditionalOptions(searchTerm, item, token);
                                              lastSearchTermRef.current = searchTerm; 
                                            }
                                            else if(searchTerm.length >= item?.search_char_length && searchTerm !== lastSearchTermRef.current) {
                                              handleFetchWithDebounce(searchTerm, item, token, item?.search_delay_time);
                                              lastSearchTermRef.current = searchTerm;
                                            }
                                            return options;
                                          }}
                                          className='custom-multiselect-branch'
                                          valueRenderer={(
                                            selected,
                                            _options
                                          ) => {
                                            if (selected?.length === 0) {
                                              return `${item?.label}`;
                                            } else {
                                              const selectedLabels = selected
                                                .map((option) => option.label)
                                                .join(', ');
                                              return `${item?.label} : ${selectedLabels}`;
                                            }
                                          }}
                                          overrideStrings={{
                                            selectSomeItems: `${item?.label}`,
                                          }}
                                          onInputChange={(searchTerm) => {
                                            
                                            if (
                                              searchTerm.length >= item?.search_char_length &&
                                              searchTerm !== lastSearchTermRef.current
                                            ) {
                                              fetchAdditionalOptions(searchTerm, item, token);
                                              lastSearchTermRef.current = searchTerm; 
                                            }
                                          }}
                                        />
                                      </div>
                                    ) :

                                    item?.type === 'lookup' && item?.operators[0]?.is_multiselect === 0 ? (
                                      <>
                                      <div
                                        key={index}
                                        className='mt-4 mb-2 ml-4 mr-24'
                                        ref={(el) =>
                                          (dropdownRefs.current[item.label] =
                                            el)
                                        } // Use refs for each dropdown
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          handleCallDBFetcherDynamic(
                                            item.operators[0]?.url,
                                            item.operators[0]
                                              ?.fetch_instruction,
                                            item.label
                                          );
                                          handleDropdownClick(item.label);
                                        }}
                                      >
                                      <Select
                                            styles={customStyles}
                                            classNamePrefix='select'
                                            className='filter-dropdown-single-select'
                                            placeholder={`${item?.label}`}
                                            components={{ SingleValue: CustomSingleValue }}
                                            customLabel={item?.label}
                                            //placeholder='Select Operator'
                                            value={lookupValue[item.label] || []}
                                            options={
                                              loadingOptions
                                                ? loadingOptionShow
                                                : optionsMap[item.label] || []
                                            }
                                            //options={filter?.operators}
                                            onChange={(e) => {
                                              handleDropdownChangeSingleSelect(
                                                item?.label,
                                                e,
                                                item
                                              );
                                            }}
                                          />
                                      </div>
                                      </>
                                    ) :
                                    item?.type === 'numeric' && item?.operators[0]?.value === 'between' ? (
                                      <div className='mt-6 mb-6 ml-5 mr-24'>
                                    <Typography
                                      id='range-slider'
                                      sx={{
                                        fontWeight: '600 !important',
                                        fontFamily: 'Inter !important',
                                        fontSize: '12px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginTop: '1px',
                                      }}
                                      gutterBottom
                                    >
                                      {item?.label}
                                    </Typography>
                                    <Box sx={{ width: 280 }}>
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          marginBottom: '10px',
                                          width: '296px',
                                        }}
                                      >
                                        <TextField
                                          className='slider-text-field mr-2'
                                          type='number'
                                          value={
                                            sliderInputValueChange ? quickFiltersMap[item?.label]?.min :
                                            item?.operators[0]?.options
                                              ?.selected_min
                                          }
                                          // onChange={(event) => debouncedMinInputHandleChange(event, item)}
                                          onChange={(event) => {
                                            handleMinInputChange(event, item);
                                          }}
                                          variant='outlined'
                                          size='small'
                                          sx={{
                                            marginLeft: '10px',
                                            '& .MuiOutlinedInput-root': {
                                              '& fieldset': {
                                                border:
                                                  '2px solid #E3E9F4 !important',
                                                borderRadius: '9px !important',
                                              },
                                              '&:hover fieldset': {
                                                border:
                                                  '2px solid #E3E9F4 !important',
                                                borderRadius: '9px !important',
                                              },
                                              '&.Mui-focused fieldset': {
                                                border:
                                                  '2px solid #E3E9F4 !important',
                                                borderRadius: '9px !important',
                                              },
                                            },
                                          }}
                                        />
                                        <div className='text-sm m-2 ml-4 mr-4'>
                                          to
                                        </div>
                                        <TextField
                                          className='slider-text-field'
                                          value={
                                            sliderInputValueChange ? quickFiltersMap[item?.label]?.max :
                                            item?.operators[0]?.options
                                              ?.selected_max
                                          }
                                          // onChange={(event) => debouncedMaxInputHandleChange(event, item)}
                                          onChange={(event) => {
                                            handleMaxInputChange(event, item);
                                          }}
                                          variant='outlined'
                                          size='small'
                                          type='number'
                                          sx={{
                                            '& .MuiOutlinedInput-root': {
                                              '& fieldset': {
                                                border:
                                                  '2px solid #E3E9F4 !important',
                                                borderRadius: '9px !important',
                                              },
                                              '&:hover fieldset': {
                                                border:
                                                  '2px solid #E3E9F4 !important',
                                                borderRadius: '9px !important',
                                              },
                                              '&.Mui-focused fieldset': {
                                                border:
                                                  '2px solid #E3E9F4 !important',
                                                borderRadius: '9px !important',
                                              },
                                            },
                                          }}
                                        />
                                      </div>

                                      <Slider
                                        getAriaLabel={() => 'Temperature range'}
                                        value={[
                                          quickFiltersMap[item?.label]?.min ||
                                            item?.operators[0]?.options
                                              ?.selected_min,
                                          quickFiltersMap[item?.label]?.max ||
                                            item?.operators[0]?.options
                                              ?.selected_max,
                                        ]}
                                        onChange={(event, newValue) => {
                                          handleChange(item, event, newValue);
                                        }}
                                        onChangeCommitted={
                                          handleChangeCommitted
                                        }
                                        min={item?.operators[0]?.options?.min}
                                        max={item?.operators[0]?.options?.max}
                                        valueLabelDisplay='auto'
                                        sx={{
                                          mx: 2,
                                          flexGrow: 1,
                                          color: 'primary.main',
                                          '& .MuiSlider-thumb': {
                                            backgroundColor: 'var(--primary)',
                                            height: 20,
                                            width: 10,
                                            borderRadius: 2,
                                          },
                                          '& .MuiSlider-track': {
                                            backgroundColor: 'var(--primary)',
                                            color: 'var(--primary)',
                                          },
                                          '& .MuiSlider-rail': {
                                            backgroundColor: 'grey.400',
                                          },
                                        }}
                                      />
                                    </Box>
                                    </div>
                                    ) : item?.type === 'divider' && item?.operators[0]?.is_multiselect === 0 ? (
                                      <div key={index} className="divider-section mt-4 mb-4 pl-4 pr-2">
                                        <hr className="border-t border-gray-900 w-full" />
                                      </div>
                                    ) : item?.type === 'divider_text' && item?.operators[0]?.is_multiselect === 0 ? (
                                      <div key={index} className="divider-section mt-4 mb-4">
                                        <h4 className="font-bold text-md text-gray-700 ml-4">{item.label}</h4>
                                      </div>
                                    ) :
                                    item?.type === 'data_limit' && item?.operators[0]?.is_multiselect === 0 ? (
                                      <>
                                      <div
                                        key={index}
                                        className='mt-4 mb-2 ml-4 mr-24'
                                        ref={(el) =>
                                          (dropdownRefs.current[item.label] =
                                            el)
                                        } // Use refs for each dropdown
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          handleCallDBFetcherDynamic(
                                            item.operators[0]?.url,
                                            item.operators[0]
                                              ?.fetch_instruction,
                                            item.label
                                          );
                                          handleDropdownClick(item.label);
                                        }}
                                      >
                                      <Select
                                            styles={customStyles}
                                            classNamePrefix='select'
                                            className='filter-dropdown-single-select'
                                            placeholder={`${item?.label}`}
                                            components={{ SingleValue: CustomSingleValue }}
                                            customLabel={item?.label}
                                            //placeholder='Select Operator'
                                            value={lookupValue[item.label] || []}
                                            options={
                                              loadingOptions
                                                ? loadingOptionShow
                                                : optionsMap[item.label] || []
                                            }
                                            //options={filter?.operators}
                                            onChange={(e) => {
                                              handleDropdownChangeSingleSelectUpdate(
                                                item?.label,
                                                e,
                                                item
                                              );
                                            }}
                                          />
                                      </div>
                                      </>
                                    ) :
                                    <></>}
                                  </>
                                );
                              })}
                            {quickFilterLookup?.length > 0 &&
                              quickFilterLookup?.map((item) => {
                                return item?.type === 'date' ? (
                                  <div className='mt-2 mb-5 ml-4 mr-24'>
                                    <Typography
                                      id='range-slider'
                                      sx={{
                                        fontWeight: '600 !important',
                                        fontFamily: 'Inter !important',
                                        fontSize: '12px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginTop: '1px',
                                      }}
                                      gutterBottom
                                    >
                                      {item?.label} between
                                    </Typography>
                                    <Box sx={{ width: 280 }}>
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          marginBottom: '10px',
                                          width: '315px',
                                        }}
                                      >
                                        <input
                                          type='date'
                                          className='w-full rounded-lg link-txt mt-1 text-xs'
                                          value={
                                            quickFiltersMap[item?.label]?.min ||
                                            item?.operators[0]?.options
                                              ?.selected_min
                                          }
                                          min={item?.operators[0]?.options?.min}
                                          max={item?.operators[0]?.options?.max}
                                          onChange={(e) => {
                                            handleFromDateChange(e, item);
                                          }}
                                        />
                                        <div className='text-sm m-3 ml-4 mr-4'>
                                          &
                                        </div>
                                        <input
                                          type='date'
                                          className='w-full rounded-lg link-txt mt-1 text-xs'
                                          value={
                                            quickFiltersMap[item?.label]?.max ||
                                            item?.operators[0]?.options
                                              ?.selected_max
                                          }
                                          min={item?.operators[0]?.options?.min}
                                          max={item?.operators[0]?.options?.max}
                                          onChange={(e) => {
                                            handleToDateChange(e, item);
                                          }}
                                        />
                                      </div>
                                    </Box>
                                    <button
                                      className='btn-popup px-4 py-2 rounded-lg text-xs ml-60'
                                      onClick={() => {
                                        updateDynamicFilterData(item, [
                                          quickFiltersMap[item?.label]?.min,
                                          quickFiltersMap[item?.label]?.max,
                                        ],
                                        filterData);
                                      }}
                                    >
                                      Apply
                                    </button>
                                    {/* <Box sx={{ width: 280 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', width: "296px" }}>
              <TextField
                className='slider-text-field mr-2'
                value={item?.operators[0]?.options?.selected_min}
                onChange={(event) => {handleMinInputChange(event, item);}}
                variant="outlined"
                size="small"
                type="date"
                sx={{
                  marginLeft: "10px",
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '2px solid #E3E9F4 !important',
                      borderRadius: "9px !important"
                    },
                    '&:hover fieldset': {
                      border: '2px solid #E3E9F4 !important',
                      borderRadius: "9px !important"
                    },
                    '&.Mui-focused fieldset': {
                      border: '2px solid #E3E9F4 !important',
                      borderRadius: "9px !important"
                    },
                  },
                }}
              />
              <div className='text-sm m-2 ml-4 mr-4'>&</div>
              <TextField
                className='slider-text-field'
                value={item?.operators[0]?.options?.selected_min}
                onChange={(event) => {handleMaxInputChange(event, item)}}
                variant="outlined"
                size="small"
                type="date"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '2px solid #E3E9F4 !important',
                      borderRadius: "9px !important",
                    },
                    '&:hover fieldset': {
                      border: '2px solid #E3E9F4 !important',
                      borderRadius: "9px !important"
                    },
                    '&.Mui-focused fieldset': {
                      border: '2px solid #E3E9F4 !important',
                      borderRadius: "9px !important"
                    },
                  },
                }}
              />
            </div>
            <Slider
              getAriaLabel={() => 'Temperature range'}
              onChange={(event, newValue) => handleChange(item, event, newValue)}
              onChangeCommitted={handleChangeCommitted}
              value={[
                item?.operators[0]?.options?.selected_min ,  
                item?.operators[0]?.options?.selected_max 
              ]}
              min={quickFiltersMap[item?.label]?.min}
              max={quickFiltersMap[item?.label]?.max}
              valueLabelDisplay="auto"
              valueLabelFormat={(val) => unscaleValue(val, scaleFactor)}
              sx={{
                mx: 2,
                flexGrow: 1,
                color: 'primary.main', 
                '& .MuiSlider-thumb': {
                  backgroundColor: 'var(--primary)',
                  height: 20,
                  width: 10,
                  borderRadius: 2,
                },
                '& .MuiSlider-track': {
                  backgroundColor: 'var(--primary)',
                  color: 'var(--primary)'
                },
                '& .MuiSlider-rail': {
                  backgroundColor: 'grey.400', 
                },
              }}
            />
          </Box> */}
                                  </div>
                                ) : (
                                  <></>
                                );
                              })}

                            {filterLookup?.length > 0 && (
                              <div className='mt-1 mb-5 ml-4'>
                                <Select
                                  styles={customStyles}
                                  classNamePrefix='select'
                                  className='select-icon'
                                  placeholder='Add Filter'
                                  value={filterApply}
                                  options={filterLookup}
                                  onChange={(e) => {
                                    if (e.type === 'lookup') {
                                      setLookupShow(true);
                                      handleCallDBFetcher(
                                        e.operators.url,
                                        e.operators.fetch_instruction
                                      );
                                      setFilter(e);
                                    } else {
                                      setFilterApply(true);
                                      setDynamicFilterData({
                                        ...dynamicFilterData,
                                        fltr: e.fil,
                                        filter_label: e.label,
                                        type: e.type,
                                      });
                                    }
                                    setFilter(e);
                                  }}
                                  required
                                />

                                {filterApply && (
                                  <div className='modalPopup relative  my-5 text-custom text-sm flex justify-between flex-col h-auto'>
                                    <div className=' flex-grow overflow-y-auto '>
                                      {/* <div className='flex justify-between items-center gap-5'>                
              </div> */}
                                      <div
                                        style={{
                                          fontSize: 14,
                                          marginBottom: '10px',
                                          fontWeight: '600',
                                        }}
                                      >
                                        {filter?.label}
                                      </div>
                                      <div className='flex flex-col'>
                                        <div className='mt-1 mb-5'>
                                          <Select
                                            styles={customStyles}
                                            classNamePrefix='select'
                                            className='filter-dropdown'
                                            placeholder='Select Operator'
                                            options={filter?.operators}
                                            onChange={(e) => {
                                              setFilterOperator(e);
                                              setDynamicFilterData({
                                                ...dynamicFilterData,
                                                optr: e.optr,
                                                filter_operator: e.label,
                                              });
                                            }}
                                            required
                                          />
                                        </div>

                                        {filter?.type === 'text' &&
                                        filterOperator ? (
                                          <div>
                                            <div className='flex flex-col gap-1 text-xs'>
                                              <input
                                                type='text'
                                                style={{
                                                  width: 'auto',
                                                  padding:
                                                    '12px 14px !important',
                                                }}
                                                value={value || ''}
                                                onChange={(e) => {
                                                  setValue(e.target.value);
                                                  setDynamicFilterData({
                                                    ...dynamicFilterData,
                                                    value: e.target.value,
                                                  });
                                                }}
                                                placeholder='Enter a Value'
                                                className='custom-input-filter py-2 px-2 text-left mt-1 mr-4'
                                                required
                                              />
                                            </div>
                                          </div>
                                        ) : filter?.type === 'numeric' &&
                                          filterOperator?.number_of_operands ===
                                            1 ? (
                                          <div>
                                            <div className='flex flex-col gap-1 text-xs'>
                                              <input
                                                type='number'
                                                style={{
                                                  width: 'auto',
                                                  padding:
                                                    '12px 14px !important',
                                                }}
                                                value={value || ''}
                                                onChange={(e) => {
                                                  setValue(e.target.value);
                                                  setDynamicFilterData({
                                                    ...dynamicFilterData,
                                                    value: e.target.value,
                                                  });
                                                }}
                                                placeholder='Enter a Value'
                                                className='custom-input-filter py-2 px-2 text-left mt-1 mr-4'
                                                required
                                              />
                                            </div>
                                          </div>
                                        ) : filter?.type === 'numeric' &&
                                          filterOperator?.number_of_operands ===
                                            2 ? (
                                          <div className='flex flex-col'>
                                            <div className='flex flex-col gap-1 ml-6 text-xs'>
                                              <input
                                                type='number'
                                                style={{ width: 'auto' }}
                                                value={value || ''}
                                                onChange={(e) => {
                                                  setValue(e.target.value);
                                                  setDynamicFilterData({
                                                    ...dynamicFilterData,
                                                    array_value: [
                                                      e.target.value,
                                                    ],
                                                  });
                                                }}
                                                placeholder='Enter a Value'
                                                className='custom-input-filter py-2 px-2 text-left mt-1 mr-2'
                                                required
                                              />
                                            </div>{' '}
                                            <div className='ml-4 mt-4'>and</div>
                                            <div className='flex flex-col gap-1 ml-6 text-xs'>
                                              <input
                                                type='number'
                                                style={{ width: 'auto' }}
                                                value={value2 || ''}
                                                onChange={(e) => {
                                                  setValue2(e.target.value);
                                                  setDynamicFilterData({
                                                    ...dynamicFilterData,
                                                    array_value: [
                                                      value,
                                                      e.target.value,
                                                    ],
                                                  });
                                                }}
                                                placeholder='Enter a Value'
                                                className='custom-input-filter py-2 px-2 text-left mt-1 mr-2'
                                                required
                                              />
                                            </div>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                    {/* bottom buttom */}
                                    <div className='spacing-div'>
                                      <button
                                        type='button'
                                        className='cancel-btn-popup px-4 py-2 rounded-lg mt-2 text-xs'
                                        onClick={() => {
                                          setFilterApply(false);
                                          setValue('');
                                          setFilterOperator();
                                        }}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        type='submit'
                                        className='btn-popup px-4 py-2 rounded-lg mt-2 text-xs'
                                        onClick={() => {
                                          handleSubmit(dynamicFilterData, null);
                                        }}
                                      >
                                        OK
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          {/* Popup for filter save */}
                          {filterSave && (
                            <>
                              <div className='overlay-filter-save-popup'>
                                <form
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    if (filterName.trim() === '') {
                                      alert('Filter name is required.');
                                      return;
                                    }
                                    handleSaveFilter();
                                  }}
                                  style={{ width: '25vw' }}
                                  className='modalPopup rounded-3xl py-7 px-9 relative mx-28 my-5 text-custom text-sm flex justify-between flex-col h-auto w-1/2'
                                >
                                  <div className='filter-popup-content'>
                                    <label>
                                      Name of filter
                                      <span className=' text-red-500 '>*</span>
                                    </label>
                                    <input
                                      type='text'
                                      style={{
                                        width: '100%',
                                        height: '40px',
                                        padding: '8px',
                                      }}
                                      value={filterName}
                                      onChange={(e) => {
                                        setFilterName(e.target.value);
                                      }}
                                      placeholder='Name of filter'
                                      className='custom-input-filter py-2 px-2 text-left mt-4 mr-4'
                                      required
                                    />
                                    {isPublishPrivileged === 1 && (
                                    <div className='checkbox-group mt-4'>
                                      <label className='flex items-center mt-2'>
                                          <input
                                            type='checkbox'
                                            onChange={(e) => setPublishToAllDashboards(e.target.checked)}
                                            className='mr-1'
                                          />
                                          Publish to all dashboards<i class="fa-solid fa-thumbtack ml-1" style={{color:'#828282'}}></i>
                                        </label>
                                        <label className='flex items-center mt-2'>
                                          <input
                                            type='checkbox'
                                            onChange={(e) => setPublishToAllUsers(e.target.checked)}
                                            className='mr-1'
                                          />
                                          Publish to all users <i class="fa-solid fa-user-group ml-1" style={{color:'#828282'}}></i>
                                        </label>
                                      </div>
                                    )}
                                    <div className='button-container flex justify-end mt-4'>
                                      <button
                                        type='button'
                                        className='cancel-btn-popup px-4 py-2 rounded-lg mr-2 text-xs'
                                        onClick={() => {
                                          setFilterName('');
                                          setFilterSave(false);
                                          setIsPublishPrivileged(0);
                                          setPublishToAllDashboards(false);
                                          setPublishToAllUsers(false);
                                        }}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        type='submit'
                                        className='btn-popup px-4 py-2 rounded-lg text-xs'
                                        disabled={filterName.trim() === ''}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </>
                          )}
                          {/* Popup for filter edit */}
                          {filterEdit && (
                            <>
                              <div className='overlay-filter-save-popup'>
                                <form
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    if (filterName.trim() === '') {
                                      alert('Filter name is required.');
                                      return;
                                    }
                                    handleSavedFilterUpdate();
                                  }}
                                  style={{ width: '25vw' }}
                                  className='modalPopup rounded-3xl py-7 px-9 relative mx-28 my-5 text-custom text-sm flex justify-between flex-col h-auto w-1/2'
                                >
                                  <div className='filter-popup-content'>
                                    <label>
                                      Name of filter
                                      <span className=' text-red-500 '>*</span>
                                    </label>
                                    <input
                                      type='text'
                                      style={{
                                        width: '100%',
                                        height: '40px',
                                        padding: '8px',
                                      }}
                                      value={filterName}
                                      onChange={(e) => {
                                        setFilterName(e.target.value);
                                      }}
                                      placeholder='Name of filter'
                                      className='custom-input-filter py-2 px-2 text-left mt-4 mr-4'
                                      required
                                    />
                                    {isPublishPrivileged === 1 && (
                                    <div className='checkbox-group mt-4'>
                                      <label className='flex items-center mt-2'>
                                          <input
                                            type='checkbox'
                                            checked={publishToAllDashboards}
                                            onChange={(e) => setPublishToAllDashboards(e.target.checked)}
                                            className='mr-1'
                                          />
                                          Publish to all dashboards <i class="fa-solid fa-thumbtack ml-1" style={{color:'#828282'}}></i>
                                        </label>
                                        <label className='flex items-center mt-2'>
                                          <input
                                            type='checkbox'
                                            checked={publishToAllUsers}
                                            onChange={(e) => setPublishToAllUsers(e.target.checked)}
                                            className='mr-1'
                                          />
                                          Publish to all users <i class="fa-solid fa-user-group ml-1" style={{color:'#828282'}}></i>
                                        </label>
                                      </div>
                                    )}
                                    <div className='button-container flex justify-end mt-4'>
                                      <button
                                        type='button'
                                        className='cancel-btn-popup px-4 py-2 rounded-lg mr-2 text-xs'
                                        onClick={() => {
                                          setFilterName('');
                                          setFilterEdit(false);
                                          setIsPublishPrivileged(0);
                                          setPublishToAllDashboards(false);
                                          setPublishToAllUsers(false);
                                          setFilterEditData({});
                                        }}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        type='submit'
                                        className='btn-popup px-4 py-2 rounded-lg text-xs'
                                        disabled={filterName.trim() === ''}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </>
                          )}
                          {/* Popup for filter delete */}
                          {filterDelete && (
                            <>
                              <div className='overlay-filter-save-popup'>
                                <form
                                     onSubmit={(e) => {
                                      e.preventDefault();
                                      handleSavedFilterDelete();
                                    }}
                                    style={{ width: '25vw' }}
                                  className='modalPopup rounded-3xl py-7 px-9 relative mx-28 my-5 text-custom text-sm flex justify-between flex-col h-auto w-1/2'
                                >
                                  <div className='filter-popup-content'>
                                    <p>Are you sure you want to delete <b>{filterName}</b>?</p>
                                    <div className='button-container flex justify-end mt-4'>
                                      <button
                                        type='button'
                                        className='cancel-btn-popup px-4 py-2 rounded-lg mr-2 text-xs'
                                        onClick={() => {
                                          setFilterName('');
                                          setFilterDelete(false);
                                          setFilterEditData({});
                                        }}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        type='submit'
                                        className='btn-popup px-4 py-2 rounded-lg text-xs'
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className='h-40'>
                        <button
                          type='button'
                          class='cancel-btn-popup px-4 py-2 rounded-lg text-sm text-medium'
                          onClick={() => {
                            setFilterClose(true);
                          }}
                          title='filter data for dashboard'
                          style={{ margin: '10px' }}
                        >
                          <div className='flex flex-col'>
                            <i className='fa-solid fa-filter'></i>
                            <i class='fa-solid fa-arrow-left text-xs'></i>
                          </div>
                        </button>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <>
                  <div className='flex flex-col h-3/4 items-center justify-center mt-32'>
                    <div className='mb-8'></div>
                    <p>
                      <b>This Organisation has no Dashboards</b>
                    </p>
                    <p className='text-sm'>
                      Create first Dashboard for {selectedOrganization?.name}
                    </p>
                    <div className='mt-5'>
                      <button
                        className='sub-grp px-3 py-2'
                        onClick={() => {
                          navigate('/dashboard_manage');
                        }}
                      >
                        New Dashboard
                      </button>
                    </div>
                  </div>
                </>
              )) : (
                
                <div className='flex items-center justify-center h-full mt-80'>
                  <div className="spinner-border" role="status">
                      <i className="fa-solid fa-spinner fa-spin-pulse"
                          style={{ marginTop: "20px", fontSize: "48px" }} 
                      ></i>
                  </div>
                </div>
              )}

              {/* -- */}
            </div>
          </div>
        </div>
        {alertModalShow && (
          <>
            {showOkButton ? (
              <Notification message={alertMessage} notificationType={'Error'} />
            ) : (
              <Notification
                message={alertMessage}
                notificationType={'Success'}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
