import React, { PureComponent, useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import * as XLSX from 'xlsx';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import * as htmlToImage from 'html-to-image';

const RCMultipleBarChart = ({
  apiData,
  item,
  setOpenDeleteChartModal,
  setOpenEditChartModal,
  currPage,
  setChartSlug,
  setDashChartSlug,
}) => {
  const [noDataFound, setNoDataFound] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [yKeys, setYKeys] = useState([]);
  // const [formData, setFormData] = useState({});
  const [chartType, setChartType] = useState("export");

    // method - chart optons
    const UpdateType = (menu) => {
        if (menu !== "image" && menu !== "export") {
            setChartType(menu?.trim()?.toLowerCase());
        }
        else if (menu === "export") {
            // export to excel functionlity
            exportToExcel((name || "Export"), data);
        }
        else {
            // Download as Image
            //OnDownloadClick("chartDashboard");
        }
    }

    // method - download chart as an Image (.png)
  //   const OnDownloadClick = async (tagName) => {
  //     await htmlToImage.toPng(document.getElementsByClassName(tagName)[0])
  //         .then(function (dataUrl) {
  //             const link = document.createElement('a')
  //             link.download = "Chart"
  //             link.href = dataUrl
  //             link.click()
  //         })
  //         .catch((err) => {
  //             // nothing
  //         });
  // }

  const exportToExcel = (filename, data) => {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
      });

    const date = new Date();

    const formattedFileName = `${filename}_${date.getMonth() + 1}_${date.getDate()}_${date.getFullYear()}_${date.getHours()}${date.getMinutes()}${date.getSeconds()}${date.getMilliseconds()}`

    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `${formattedFileName}.xlsx`);
};

const optionsExport = ["export"];


  const transformData = (data, group, xCord, yCord) => {
    const groupedData = data?.reduce((acc, curr) => {
      if (!acc[curr[xCord]]) {
        acc[curr[xCord]] = { [xCord]: curr[xCord] };
      }
      acc[curr[xCord]][curr[group]] = curr[yCord];
      return acc;
    }, {});

    setIsLoading(false);

    return Object.values(groupedData);
  };

  const generateColors = (count) => {
    const baseColors = [
      "#3366cc", // Blue
      "#dc3911", // Red
      "#ff9900", // Orange
      "#0d9618", // Green
      "#990099", // Purple
      "#0099c6", // Cyan
      "#dd4477", // Pink
      "#66aa02", // Lime
      "#b82e2f", // Dark Red
      "#326395", // Dark Blue
    ];
  
    const mixColors = (color1, color2, weight) => {
      let d2h = (d) => d.toString(16).padStart(2, '0'); // Converts decimal to hex
      let h2d = (h) => parseInt(h, 16); // Converts hex to decimal
  
      let color = "#";
      for (let i = 1; i <= 5; i += 2) {
        // Get the hex values of both colors, mix them by weight
        let v1 = h2d(color1.substr(i, 2)),
          v2 = h2d(color2.substr(i, 2)),
          val = d2h(Math.floor(v2 + (v1 - v2) * weight));
        color += val;
      }
      return color;
    };
  
    const colors = [];
    for (let i = 0; i < count; i++) {
      if (i < baseColors.length) {
        colors.push(baseColors[i]);
      } else {
        // Blend two base colors for additional colors
        const color1 = baseColors[i % baseColors.length];
        const color2 = baseColors[(i + 1) % baseColors.length];
        const weight = (i % baseColors.length) / baseColors.length; // Weight to blend between colors
        colors.push(mixColors(color1, color2, weight));
      }
    }
  
    return colors;
  };
   

  const colors = generateColors(yKeys.length);

  useEffect(() => {
    //setIsLoading(false);
    if (item?.data.length > 0 && item?.data !== "fetching") {
      const chartdata = transformData(
        apiData?.data,
        apiData?.group,
        apiData?.x_cord,
        apiData?.y_cord
      );
      setData(chartdata);
      const keys = [...new Set(apiData?.data.map((d) => d[apiData?.group]))];
      setYKeys(keys);
      setIsLoading(false);
    } else if (item?.data === "fetching") {
      setIsLoading(true);
    } else {
      setNoDataFound("No data found");
      setIsLoading(false);
    }
  }, [isLoading, apiData, item]);
  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  

  return (
    <>
      {noDataFound ? (
        <>
          <div className="chart-title">{noDataFound}</div>
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {item?.title !== "" ? (
            <div className="flex flex row justify-between">
              <div className="chart-title" style={{padding: "12px"}}>{item?.title} </div> 

              {optionsExport?.length ? 
                    <div><OverlayTrigger trigger="click" rootClose placement="bottom" overlay={<Popover id="popover-basic">
                        <Popover.Body>
                            {optionsExport?.map((menu, index) => (
                                <div key={menu} className={`${optionsExport.length !== (index + 1) ? "border-bottom chart-type-options" : ""}`}>
                                    <button className='pb-1 pt-1' onClick={() => UpdateType(menu?.trim()?.toLowerCase())} style={{ textTransform: "capitalize", border: "none", backgroundColor: "transparent" }} key={menu}>{menu}</button>
                                </div>
                            ))}
                        </Popover.Body>
                    </Popover>}>
                        <span className='pe-2' title='Filters' style={{ cursor: "pointer" }}>
                            <i className="fa-solid fa-ellipsis-vertical not-0" style={{color: "rgba(9, 8, 38, 0.6)", fontSize: "20px", marginBottom: "13px"}}></i>
                            {/* <FontAwesomeIcon style={{fontSize: "16px"}} icon={faEllipsisVertical} /> */}
                            </span>
                    </OverlayTrigger></div> : null}
              </div>

          ) : (
            
            <div className="no-chart-title flex flex-row justify-between">
            <div></div>
            {optionsExport?.length ? 
                <div><OverlayTrigger trigger="click" rootClose placement="bottom" overlay={<Popover id="popover-basic">
                    <Popover.Body>
                        {optionsExport?.map((menu, index) => (
                            <div key={menu} className={`${optionsExport.length !== (index + 1) ? "border-bottom chart-type-options" : ""}`}>
                                <button className='pb-1 pt-1' onClick={() => UpdateType(menu?.trim()?.toLowerCase())} style={{ textTransform: "capitalize", border: "none", backgroundColor: "transparent" }} key={menu}>{menu}</button>
                            </div>
                        ))}
                    </Popover.Body>
                </Popover>}>
                    <span className='pe-2' title='Filters' style={{ cursor: "pointer" }}>
                        <i className="fa-solid fa-ellipsis-vertical not-0" style={{color: "rgba(9, 8, 38, 0.6)", fontSize: "20px", marginBottom: "13px"}}></i>
                        {/* <FontAwesomeIcon style={{fontSize: "16px"}} icon={faEllipsisVertical} /> */}
                        </span>
                </OverlayTrigger></div> : null}
          </div>
          )}
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}
      {isLoading === true && item?.data === "fetching" ? (
        <i
          className="fa-solid fa-spinner fa-spin-pulse"
          style={{ marginTop: "20px" }}
        ></i>
      ) : (
        <div style={{ height: "350px" }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                dataKey={item?.x_cord}
                label={{
                  value: item?.x_axis_label,
                  position: "insideBottom",
                  offset: -5, // Adjusted offset for better positioning
                  fontSize: 14,
                  fontWeight: 600,
                  fill: "#000",
                }}
              />
              <YAxis
                label={{
                  value: item?.y_axis_label,
                  angle: -90,
                  position: "insideLeft",
                  offset: 10,
                  dy: 50,
                }}
              />
              <Tooltip
                contentStyle={{
                  fontSize: "14px",
                  padding: "10px",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "5px",
                }}
              />
              <Legend 
                 layout="horizontal"
                 align="center"
                 verticalAlign="bottom"
                 wrapperStyle={{ fontSize: '12px', padding: '8px' }}
             />
             {yKeys?.map((key, index) => {
                return (
                  <Bar
                    type="monotone"
                    dataKey={key}
                    fill={colors[index]}
                    isAnimationActive={true}
                    animationEasing="ease-out"
                    animationDuration={100}
                  />
                );
              })}
              {/* <Bar dataKey={item?.y_cord} fill="#8884d8" /> */}
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}{" "}
    </>
  );
};

export default RCMultipleBarChart;