import React from 'react'
import {useNavigate} from 'react-router-dom';
import user from '../../Asset/Images/userprofile.png'
import { useContext } from 'react'
import { Context } from '../../contextApi/context'
import { Link } from 'react-router-dom'
import UserService from '../../services/UserService'


function ProfileDropdown({profileRef}) {
    const navigate = useNavigate();
    const {isActive, isDarkTheme} = useContext(Context);
    const handleLogout = () => {
      UserService.doLogout();
      //navigate("/");
      sessionStorage.clear();
    }
  return (
    <div ref={profileRef} className={isDarkTheme ? 'profile p-2 w-40 rounded-md bg-neutral-700 absolute top-6 left-2' : 'profile p-2 w-40 rounded-md bg-white absolute top-6 left-2'}>
    <div className='flex gap-5 justify-start my-2 text-custom' style={{alignItems:'center'}}>
    <img className='rounded-full' height={30} width={30} src={user} alt='user-img'/>
    <div>
    <div className='profile-name'>{UserService.getWelcomeName()}</div>
    <div className='flex justify-start gap-1' style={{alignItems:'center'}}>
  {isActive?  <i class="fa-solid fa-circle text-green-400"></i>: <i class="fa-solid fa-circle text-gray-400"></i>}
    <div className='isactive'> {isActive?'Active':'Away' }</div>
    </div>
    </div>
   
    </div>
  {/* <div className='profileCards py-2'>
  <Link to={'/'} className='p-1 profilehover'>Set yourself active</Link>
  </div>
  <div className='profileCards  py-2 '>
  <Link to={'/'} className='p-1 profilehover'>Profile</Link>
  <Link to={'/'} className='p-1 profilehover'>User settings & billing</Link>
  </div>
    
  <div className='profileCards  py-2'>
  <Link to={'/'} className='p-1 profilehover'>Support</Link>
  <Link to={'/'} className='p-1 profilehover'>Download</Link>
  <Link to={'/'} className='p-1 profilehover'>Developer API</Link>

  </div> */}

  <div className='profileCards p-2 text-custom'>
    <Link to={'/'} className='profilehover p-1' onClick={handleLogout}>
    Sign out  
    </Link>
 
  </div>
  </div>
  )
}

export default ProfileDropdown