import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import logo from "./../Asset/Images/logoTM.png";
import dbtez from "./../Asset/Images/dbtez.png";
import logoSmall from "./../Asset/Images/logoTMshort.png";
import { Context } from "./../contextApi/context";
import Select from "react-select";
import { useContext } from "react";
// import Search from '../Elements/Search'
import { notificationData } from "./../Asset/Data/NotificationData";
import { Link } from "react-router-dom";
import axios from "axios";
import NewOrganizationPopup from "../kz-organization/NewOrganizationPopup";
import UserService from "../services/UserService";
import Notification from "../common/notification/Notification.js";
import user from "./../Asset/Images/userprofile.png";
import ProfileDropdown from "./ProfileDropdown/ProfileDropdown";

const serverConfig = require("./../config/server.js");

function NavOptions({
  icons,
  activeOption,
  setActiveOption,
  title,
  collapse,
  link,
  childMenu,
  scrolledDist,
}) {
  const { activeSubmenuOption, setActiveSubmenuOption, selectedOrganization } =
    useContext(Context);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const [seletedLabel, setSelectedLabel] = useState("");
  const dropdownRef = useRef(null);
  const menuRef = useRef(null);

  const token = sessionStorage.getItem("token");

  const toggleTooltip = () => {
    setShowTooltip(true);
  };

  const toggleTooltip2 = () => {
    setShowTooltip(false);
  };

  useEffect(() => {
    setDropdownOptions(childMenu);
    if (childMenu !== null) {
      setSelectedLabel(childMenu[0]?.title);
    }

    console.log(activeOption, "activeOption");
    console.log(title, "title");

    axios
      .post(serverConfig.api_base_url + "app_user_context_set", {
        token: token,
        scope: "org",
        context_name: "active_menu_option",
        value: activeOption,
        org: selectedOrganization?.slug,
      })
      .then((response) => {
        if (response.data.status === 200) {
          // Success code
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          // alert(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err));
  }, [activeOption]);

  let currentPagePathWithSlug =
    window.location.pathname + window.location.search;

  if (currentPagePathWithSlug.includes("dashboard_manage_layout")) {
    currentPagePathWithSlug = currentPagePathWithSlug.replace(
      "dashboard_manage_layout",
      "dashboard_manage"
    );
  }

  useEffect(() => {
    axios
      .post(serverConfig.api_base_url + "app_user_context_set", {
        token: token,
        scope: "org",
        context_name: "current_app_context",
        value: currentPagePathWithSlug,
        org: selectedOrganization?.slug,
      })
      .then((response) => {
        if (response.data.status === 200) {
          // Success code
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          // alert(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err));
  }, [currentPagePathWithSlug]);

  useEffect(() => {
    console.log(collapse);
    document.querySelector(".navoptions").style.transform = "translateY(0px)";
    setShowDropdown(false);
  }, [collapse]);

  useEffect(() => {
    console.log(showDropdown);
    if (collapse) {
      document.querySelector(".navoptions").style.transform = "translateY(0px)";
    } else {
      if (showDropdown) {
        document.querySelector(".navoptions").style.transform =
          "translateY(-" + scrolledDist + "px)";
      } else {
        document.querySelector(".navoptions").style.transform =
          "translateY(0px)";
      }
    }
  }, [showDropdown]);

  const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the dropdown is open and if the click was outside of it and the main menu
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !menuRef.current.contains(event.target)
      ) {
        setShowDropdown(false); // Close dropdown if clicked outside
      }
    };

    // Only add the event listener when the menu is collapsed
    if (!collapse) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [collapse]);

  const handleSaveOrg = () => {
    axios
      .post(serverConfig.api_base_url + "app_user_context_set", {
        token: token,
        scope: "global",
        context_name: "current_org",
        value: selectedOrganization?.slug,
      })
      .then((response) => {
        if (response.data.status === 200) {
          //Succes code
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          // alert(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  return (
    <div ref={menuRef}>
      {childMenu === null ? (
        <ConditionalWrapper
          condition={showTooltip && !collapse}
          wrapper={(children) => (
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="tooltip" className="text-sm">
                  {title}
                </Tooltip>
              }
            >
              {children}
            </OverlayTrigger>
          )}
        >
          {/* wrap this in a div so when the button is disabled, the popover still works */}
          <Link
            to={link}
            onClick={() => {
              setActiveOption(title);
              /*handleSaveMenuOption();*/
              handleSaveOrg();
            }}
            className={
              collapse
                ? `flex align-middle rounded-3xl p-3 my-3 nav-options ${
                    activeOption === title ? "activeOption" : ""
                  }`
                : `flex align-middle rounded-3xl px-3 py-4 my-2 nav-options justify-center ${
                    activeOption === title ? "activeOption" : ""
                  }`
            }
            onMouseEnter={toggleTooltip}
            onMouseLeave={toggleTooltip2}
          >
            <div
              className={
                collapse
                  ? `icon ${activeOption === title ? "textBlack" : ""}`
                  : `icon-collapse ${activeOption === title ? "textBlack" : ""}`
              }
              dangerouslySetInnerHTML={{ __html: icons }}
            ></div>
            {collapse && (
              <div
                style={{ marginTop: "0.15rem" }}
                className={`${activeOption === title ? "textBlack" : ""}`}
              >
                {title}
              </div>
            )}
          </Link>
        </ConditionalWrapper>
      ) : (
        <>
          <ConditionalWrapper
            condition={showTooltip && !collapse}
            wrapper={(children) => (
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip" className="text-sm">
                    {title}
                  </Tooltip>
                }
              >
                {children}
              </OverlayTrigger>
            )}
          >
            {/* wrap this in a div so when the button is disabled, the popover still works */}
            <Link
              to={link}
              onClick={() => {
                setShowDropdown(!showDropdown);
                setActiveOption(title);
                setActiveSubmenuOption(seletedLabel);
                handleSaveOrg(); /*handleDropdownToggle()*/
              }}
              className={
                collapse && showDropdown
                  ? `flex align-middle rounded-3xl p-3 my-3 nav-options-hovered ${
                      activeOption === title ? "activeOption" : ""
                    }`
                  : collapse
                  ? `flex align-middle rounded-3xl p-3 my-3 nav-options ${
                      activeOption === title ? "activeOption" : ""
                    }`
                  : `flex align-middle rounded-3xl px-3 py-4 my-2 nav-options justify-center ${
                      activeOption === title ? "activeOption" : ""
                    }`
              }
              onMouseEnter={toggleTooltip}
              onMouseLeave={toggleTooltip2}
            >
              <div
                className={
                  collapse
                    ? `icon ${activeOption === title ? "textBlack" : ""}`
                    : `icon-collapse ${
                        activeOption === title ? "textBlack" : ""
                      }`
                }
                dangerouslySetInnerHTML={{ __html: icons }}
              ></div>
              {collapse && (
                <div
                  className={`mt-1 ${
                    activeOption === title ? "textBlack" : ""
                  }`}
                >
                  <p style={{ width: "88px" }}>{title}</p>
                </div>
              )}
              {collapse && (
                <div
                  className={`ml-6 mt-1 ${
                    activeOption === title ? "textBlack" : ""
                  }`}
                  style={{ fontSize: "0.75rem" }}
                >
                  <i className="fas fa-angle-down"></i>
                </div>
              )}
            </Link>
          </ConditionalWrapper>

          {showDropdown && (
            <div
              className={
                collapse
                  ? "optionsDropdownCard relative"
                  : "optionsDropdownCard absolute"
              }
            >
              <div className="options-dropdown py-2 my-1">
                {childMenu?.map((data) => (
                  <OptionsDropdownCard
                    key={data.id}
                    showDropdown={showDropdown}
                    setSelectedOption={setActiveSubmenuOption}
                    title={title}
                    activeOption={activeOption}
                    setActiveOption={setActiveOption}
                    selectedOption={activeSubmenuOption}
                    dropdownRef={dropdownRef}
                    data={data}
                    selectedLabel={seletedLabel}
                    setSelectedLabel={setSelectedLabel}
                    setShowDropdown={setShowDropdown}
                  />
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

function OptionsDropdownCard({
  data,
  dropdownRef,
  showDropdown,
  selectedOption,
  setSelectedOption,
  selectedLabel,
  setShowDropdown,
  setSelectedLabel,
  title,
  activeOption,
  setActiveOption,
}) {
  const { activeSubmenuOption, selectedOrganization } = useContext(Context);

  const navigate = useNavigate();
  if (title !== activeOption) {
    setShowDropdown(false);
  }
  var isActive;
  var isOptionActive;

  useEffect(() => {
    isActive = "";
    isOptionActive = "";
  }, [showDropdown]);

  const handleOptionClick = (event) => {
    event.stopPropagation(); // Stop event propagation to prevent closing dropdown
    navigate(`${data?.link}`);
    setSelectedOption(data.title);
    //setActiveOption(title);
    setSelectedLabel(data.title);

    const token = sessionStorage.getItem("token");

    axios
      .post(serverConfig.api_base_url + "app_user_context_set", {
        token: token,
        scope: "org",
        context_name: "active_submenu_option",
        value: activeSubmenuOption,
        org: selectedOrganization?.slug,
      })
      .then((response) => {
        if (response.data.status === 200) {
          // Success code
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          // alert(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err));

    let currentPagePathWithSlug =
      window.location.pathname + window.location.search;

    if (currentPagePathWithSlug.includes("dashboard_manage_layout")) {
      currentPagePathWithSlug = currentPagePathWithSlug.replace(
        "dashboard_manage_layout",
        "dashboard_manage"
      );
    }

    axios
      .post(serverConfig.api_base_url + "app_user_context_set", {
        token: token,
        scope: "org",
        context_name: "current_app_context",
        value: currentPagePathWithSlug,
        org: selectedOrganization?.slug,
      })
      .then((response) => {
        if (response.data.status === 200) {
          // Success code
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          // alert(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  isActive = data.title === selectedOption ? "activeOption" : "";
  isOptionActive =
    data.title === selectedOption
      ? "options-heading-active"
      : "options-heading-submenu";

  return (
    <div
      ref={dropdownRef}
      id="orgHover"
      className={`flex gap-2 align-middle  p-3 oragnisationHover ${isActive}`}
      style={{ alignItems: "center" }}
      onClick={handleOptionClick}
    >
      <div>
        {/* <img src={data.logo} alt={data.logo_name} className='rounded-lg organisation-img'/> */}
      </div>

      <div className={`${isOptionActive}`}>{data.title}</div>
    </div>
  );
}

function OrganisationDropdownCard({
  data,
  cardRef,
  setSelectedOrg,
  // setDropdownOpen,
  setIsorganisationDropdown,
    isOrganisationDropdown,
}) {
  const { setSelectedOrganization, selectedOrganization } = useContext(Context);

  const handleClick = (e) => {
    let token = sessionStorage.getItem("token");

    console.log("Selected Organization", selectedOrganization);
    // console.log("Selected Org", selectedOrg);

    setSelectedOrganization(data);
    console.log(selectedOrganization);
    setSelectedOrg(data);
    setIsorganisationDropdown(false);
    // setDropdownOpen(false);

    axios
      .post(serverConfig.api_base_url + "app_user_context_set", {
        token: token,
        scope: "global",
        context_name: "current_org",
        value: data?.slug,
      })
      .then((response) => {
        if (response.data.status === 200) {
          //Succes code
        } else if (response.data.status === 301) {
          handleLogout();
        } else {
          // alert(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err));
  };

  return (
    <div>
      <div
        ref={cardRef}
        className="flex gap-2 align-middle p-2 oragnisationHover"
        style={{ alignItems: "center" }}
        onClick={handleClick}
      >
        <div>
          {data.logo === null ? (
            <img
              src={`https://ui-avatars.com/api/?name=${data?.name}&rounded=false&length=1&background=6C9BF6&format=svg&color=fff`}
              alt="image"
              className="organisation-img rounded-lg"
            />
          ) : (
            <img
              src={data.logo}
              alt={data.logo_name}
              className="rounded-lg organisation-img"
            />
          )}
        </div>
        <div className="organisation-heading line-clamp-1">{data.name}</div>
      </div>
    </div>
  );
}

function NavHeader() {
  const navigate = useNavigate();
  const [navData, setNavData] = useState([]);
  const {
    handleOrganisationDropdown,
    selectedOrganization,
    activeOption,
    setActiveOption,
    setIsorganisationDropdown,
    isOrganisationDropdown,
    collapse,
    handleCollapse,
    refreshOrgList,
    lastSelectedOrganizationSlug,
    activeTab,
  } = useContext(Context);
  const [newOrganizationPopup, setNewOrganizationPopup] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [logoBase64Text, setLogoBase64Text] = useState("");
  const [logoSmallBase64Text, setLogoSmallBase64Text] = useState("");
  const [logoHeight, setLogoHeight] = useState("");
  const [poweredByLogo, setPoweredByLogo] = useState("");
  const [displayPoweredBy, setDisplayPoweredBy] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [profilePic, setProfilePic] = useState(null);
  const profileRef = useRef(null);

  /* token - start */
  const [token, setToken] = useState(null);
  const [organizationList, setOrganizationList] = useState([]);
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  const [newOrg, setNewOrg] = useState("");
  const {
    setSelectedOrganization,
    handleProfileDropdown,
    isActive,
    isProfileDropdown,
    setIsProfileDropdown,
  } = useContext(Context);
  const cardRef = useRef(null);

  const [alertMessage, setAlertMessage] = useState("");
  const [showOkButton, setShowOkButton] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const handleCloseAlertModal = () => {
    setAlertMessage("");
    setShowOkButton(false);
    setAlertModalShow(false);
  };
  const handleShowAlertModal = (alert_msg, show_ok_button) => {
    setAlertMessage(alert_msg);
    setShowOkButton(show_ok_button);
    setAlertModalShow(true);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    window.history.replaceState({}, "", "/");
    navigate("/");
    UserService.doLogout();
  };

  useEffect(() => {
    const handleMouseDown = (event) => {
      if (
        cardRef.current &&
        !cardRef.current.contains(event.target) &&
        !event.target.classList.contains("organisation-heading") &&
        !event.target.classList.contains("custom-input") &&
        !event.target.classList.contains("select-custom-input") &&
        !event.target.classList.contains("orgCreate") &&
        !event.target.classList.contains("memberPopup") &&
        !event.target.classList.contains("newOrg") &&
        !event.target.classList.contains("oragnisationHover") &&
        !event.target.classList.contains("new") &&
        !event.target.classList.contains("select-error") &&
        !event.target.classList.contains("input-error")
      ) {
        setIsorganisationDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  useEffect(() => {
    axios
      .post(serverConfig.api_base_url + "get_logo_image", {
        domainName: window.location.host,
      })
      .then((response) => {
        console.log("get_logo_image response", response);
        if (response.data.status === 200) {
          setLogoBase64Text(response.data.logoBase64);
          setLogoSmallBase64Text(response.data.logoBase64Small);
          setLogoHeight(response.data.logoHeight + 10);
        } else if (response.data.status >= 400 && response.data.status <= 499) {
          alert(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err));

    axios
      .post(serverConfig.api_base_url + "get_system_config", {
        domain: window.location.host,
        key: "display_powered_by",
      })
      .then((response) => {
        console.log("get_system_config display_powered_by", response);
        if (response.data.status === 200) {
          if (response.data.value === "1") {
            setDisplayPoweredBy(true);
          } else if (response.data.value === "0") {
            setDisplayPoweredBy(false);
          }
        } else {
          console.log(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err));

    axios
      .post(serverConfig.api_base_url + "get_system_config", { key: "logo" })
      .then((response) => {
        console.log("get_system_config logo response", response);
        if (response.data.status === 200) {
          if (
            response.data.value !== null &&
            response.data.value !== undefined
          ) {
            setPoweredByLogo(response.data.value);
          } else {
            setPoweredByLogo("");
          }
        } else {
          console.log(response.data.message);
        }
      })
      .catch((err) => console.log("error is", err));
  }, []);

  const menuListGet = (userToken) => {
    axios
      .post(serverConfig.api_base_url + "menu_list_get", {
        token: userToken,
        org: selectedOrganization?.slug,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.status === 200) {
          console.log(response.data.items, "navdata");
          setNavData(response.data.items);
        } else if (response.data.status === 301) {
          handleLogout();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getToken = () => {
    console.log(sessionStorage.getItem("token"));
    let jwt = UserService.getToken();
    axios
      .post(serverConfig.api_base_url + "user_get_token", {
        jwt: jwt,
        url: window.location.host,
      })
      .then((response) => {
        console.log("user_get_token response", response);
        if (response.data.status === 200) {
          // if(organizationList.length > 0){
          //   navigate("/users");
          // }
          // else {
          //   navigate("/members");
          // }
          sessionStorage.setItem("token", response.data.token);
          setToken(response.data.token);
        } else if (response.data.status >= 400 && response.data.status <= 499) {
          alert(response.data.message);
        } else if (response.data.jwt_validation === "fail") {
          handleLogout();
        }
      })
      .catch((err) => console.log("error is", err));
  };

  useEffect(() => {
    if (
      selectedOrganization !== null &&
      selectedOrganization !== undefined &&
      token !== null &&
      token !== undefined
    ) {
      console.log(selectedOrganization, token, "selected_organization");
      menuListGet(token);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    // console.log(sessionStorage.getItem('token'));
    if (sessionStorage.getItem("token") === null) {
      getToken();
    } else {
      setToken(sessionStorage.getItem("token"));
    }
  }, [activeOption, selectedOrganization]);

  useEffect(() => {
    console.log(selectedOrganization?.slug, "selected_org_slug");

    if (token !== null && token !== undefined && selectedOrganization) {
      axios
        .post(serverConfig.api_base_url + "member_profile_get", {
          token: token,
          org: selectedOrganization?.slug,
        })
        .then((response) => {
          const profileData = response?.data?.item?.profile_picture;
          console.log(profileData, "PROFILE");
          if (profileData) {
            setProfilePic(profileData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token, selectedOrganization, activeOption]);

  /* token - end */

  useEffect(() => {
    console.log(window.location.host, "DOMAIN NAME");
    if (token !== null && token !== undefined) {
      axios
        .post(serverConfig.api_base_url + "organization_list_get", {
          token: token,
        })
        .then((response) => {
          console.log("organization_list_get response", response);
          const lastSelectedSlug = lastSelectedOrganizationSlug;
          if (response.data.status === 200) {
            if (response.data.items.org_list !== null) {
              const orgList = response.data.items.org_list;
              setOrganizationList(orgList);

              if (lastSelectedSlug !== "") {
                navigate(activeTab);
                const matchedIndex = orgList.findIndex((org) => {
                  return org.slug === lastSelectedSlug;
                });
                if (matchedIndex !== -1) {
                  setSelectedOrganization(orgList[matchedIndex]);
                  setSelectedOrg(orgList[matchedIndex]);
                  // setIsLoading(false);
                } else {
                  // setIsLoading(true);
                  setSelectedOrganization(orgList[orgList.length - 1]);
                  setSelectedOrg(orgList[orgList.length - 1]);
                }
                setNewOrg(response.data.items.new_org);
              }

              if (
                selectedOrganization === "" ||
                selectedOrganization === undefined
              ) {
                console.log('selectedOrganization === ""');
                navigate(activeTab);
                const matchedIndex = orgList.findIndex((org) => {
                  return org.slug === lastSelectedSlug;
                });
                if (matchedIndex !== -1) {
                  console.log("matchedIndex !== -1");
                  setSelectedOrganization(orgList[matchedIndex]);
                  setSelectedOrg(orgList[matchedIndex]);
                  // setIsLoading(false);
                } else {
                  // setIsLoading(true);
                  console.log("matchedIndex === -1");
                  console.log(orgList[orgList.length - 1]);
                  setSelectedOrganization(orgList[orgList.length - 1]);
                  setSelectedOrg(orgList[orgList.length - 1]);
                }
                setNewOrg(response.data.items.new_org);
              }
            } else {
              navigate("/members");
            }
          } else if (response.data.items.org_list === null) {
            navigate("/members");
          } else if (response.data.status === 301) {
            handleLogout();
          } else {
            alert(response.data.message);
          }
        })
        .catch((err) => console.log("error is", err));
    }
  }, [token, refreshOrgList, lastSelectedOrganizationSlug]);

  useEffect(() => {
    const handleMouseDown = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setIsProfileDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  const [scrolledDist, setScrolledDist] = useState(0);

  return (
    <div
      className={collapse ? "Navheader" : "Navheader-collapse"}
      id={collapse ? "nav-header" : "nav-header-collapse"}
      style={{ top: logoHeight + "px" }}
    >
      <div className="flex align-middle justify-between p-5 pb-0 relative z-10">
        {/* {isLoading && (
              <div className="loading absolute">
                <i className="fa-solid fa-spinner fa-spin-pulse"></i>
              </div>
            )} */}
        {/* <div className="relative overflow-x-hidden">{collapse? <img src={logoBase64Text} alt='logo' style={{"height" : logoHeight+"px", maxWidth: "none"}}/>: <img src={logoSmallBase64Text} alt='logo' style={{"height" : logoHeight+"px"}}/>}
       
      </div> */}
        <div
          className={
            collapse
              ? "relative flex justify-between items-center w-full mx-auto"
              : "relative block text-center mx-auto"
          }
        >
          {/* <span style={{color: "#FCFCFE", fontSize: collapse ? '1em' : '0.55em'}}>Amberoon</span> */}
          {/* <div className='flex gap-2 mb-2'> */}
          {organizationList.length === 1 && (
            <>
              <div className={collapse ? "" : "mb-2"}>
                {selectedOrg?.logo === null ? (
                  <img
                    src={`https://ui-avatars.com/api/?name=${selectedOrg?.name}
                      &rounded=false&length=1
                      &${"background=6C9BF6"}&format=svg&color=fff`}
                    alt="image"
                    className=" rounded-lg max-h-max"
                    width="120"
                  />
                ) : (
                  <img
                    src={organizationList.length > 0 && selectedOrg?.logo}
                    alt={organizationList.length > 0 && selectedOrg?.logo_name}
                    width="120"
                    className="rounded-lg max-h-max"
                  />
                )}
              </div>
            </>
          )}
          {/* {collapse && <div className='text-white text-xs' style={{ alignSelf: 'center' }}>{selectedOrg?.name}</div>} */}
          {/* </div> */}
          {organizationList.length > 1 && (
            <div className="relative org-selector">
              {collapse ? (
                <button
                  className="flex rounded-lg dbtez align-center justify-between text-white"
                  onClick={() => {
                    handleOrganisationDropdown();
                    setNewOrganizationPopup(false);
                    // setDropdownOpen(true);
                    setIsorganisationDropdown(true);
                  }}
                >
                  <div className="flex items-center gap-2">
                    {selectedOrg?.logo === null ? (
                      <img
                        src={`https://ui-avatars.com/api/?name=${
                          selectedOrg?.name
                        }
                              &rounded=false&length=1
                              &${"background=6C9BF6"}&format=svg&color=fff`}
                        alt="image"
                        width="120"
                        className="rounded-lg max-h-max"
                      />
                    ) : (
                      <img
                        src={organizationList.length > 0 && selectedOrg?.logo}
                        alt={
                          organizationList.length > 0 && selectedOrg?.logo_name
                        }
                        width="120"
                        className="rounded-lg max-h-max"
                      />
                    )}
                    {/* <div className='text-white text-xs' style={{alignSelf:'center'}}>{organizationList.length > 0 && selectedOrg?.name}</div> */}
                  </div>
                  <div>
                    <i class="fa-solid fa-chevron-down mx-1"></i>
                  </div>
                </button>
              ) : (
                <div className="flex align-middle justify-center mb-2">
                  {selectedOrg?.logo === null ? (
                    <img
                      src={`https://ui-avatars.com/api/?name=${
                        selectedOrg?.name
                      }
                              &rounded=false&length=1
                              &${"background=6C9BF6"}&format=svg&color=fff`}
                      alt="image"
                      className=" rounded-lg mx-3"
                      height={40}
                      width={45}
                    />
                  ) : (
                    <img
                      src={organizationList.length > 0 && selectedOrg?.logo}
                      alt={
                        organizationList.length > 0 && selectedOrg?.logo_name
                      }
                      height={40}
                      width={45}
                      className="rounded-lg mx-3"
                    />
                  )}
                </div>
              )}
              {isOrganisationDropdown && (
                <div className="organisationDropdownCard">
                  <div className="p-2">{/* <Search/> */}</div>

                  <div className="organisations py-2 my-2">
                    {organizationList.length > 0 &&
                      organizationList.map((data) => {
                        return (
                          <OrganisationDropdownCard
                            data={data}
                            cardRef={cardRef}
                            // setDropdownOpen={setDropdownOpen}
                            setSelectedOrganization={setSelectedOrganization}
                            setIsorganisationDropdown={setIsorganisationDropdown}
                            isOrganisationDropdown={setIsorganisationDropdown}
                            setSelectedOrg={setSelectedOrg}
                          />
                        );
                      })}
                  </div>
                  {/* {newOrg && organizationList.length > 1 && 
            <button className='flex gap-4 align-middle py-1 pb-2 new' style={{alignItems:'center'}} onClick={()=>{setNewOrganizationPopup(true);}}>
              <div className='text-xs plus-icon'><i class="fa-solid fa-plus"></i></div>
              <div className='organisation-heading mr-4'>New Organization</div>
            </button> } */}
                  {newOrganizationPopup && (
                    <div className="overlay newOrg">
                      {" "}
                      <NewOrganizationPopup
                        handleCloseAlertModal={handleCloseAlertModal}
                        handleShowAlertModal={handleShowAlertModal}
                        setNewOrganizationPopup={setNewOrganizationPopup}
                        token={token}
                        handleLogout={handleLogout}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          <div className="relative flex justify-center items-center">
            <button className="relative" onClick={handleProfileDropdown}>
              {isActive && (
                <i class="fa-solid fa-circle text-green-400 absolute bottom-0 right-0"></i>
              )}
              {profilePic === null ? (
                <img
                  className="rounded-full user-img"
                  height="50"
                  width="50"
                  src={user}
                  alt="user-img"
                />
              ) : (
                <img
                  className="rounded-full user-img"
                  height="50"
                  width="50"
                  src={profilePic}
                  alt="user-img"
                />
              )}
            </button>
            {isProfileDropdown && <ProfileDropdown profileRef={profileRef} />}
          </div>
        </div>
        <button
          className="button-toggle"
          onClick={() => {
            handleCollapse();
          }}
        >
          <div
            className="rounded-full circle bg-white h-5 w-5"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {collapse ? (
              <i class="fa-solid fa-arrow-left text-xs"></i>
            ) : (
              <i class="fa-solid fa-arrow-right text-xs"></i>
            )}
          </div>
        </button>
      </div>
      <div className="relative org-selector pb-3">
        {/* {collapse? <button className='flex rounded-3xl p-1 dbtez mx-4 
    align-center justify-between text-white' onClick={()=>{handleOrganisationDropdown(); setNewOrganizationPopup(false); setDropdownOpen(true);}}>
        <div className='flex gap-2'>
        {selectedOrg?.logo === null ? <img src={`https://ui-avatars.com/api/?name=${selectedOrg?.name}
                        &rounded=false&length=1
                        &${
                        'background=6C9BF6'}&format=svg&color=fff`} alt='image'
                          className=' rounded-lg' height={30} width={30}/> :<img src={organizationList.length > 0 && selectedOrg?.logo} alt={organizationList.length > 0 && selectedOrg?.logo_name} height={30} width={30} className='rounded-lg'/> }
     <div className='text-white text-xs' style={{alignSelf:'center'}}>{organizationList.length > 0 && selectedOrg?.name}</div>
        </div>
      <div ><i class="fa-solid fa-chevron-down"></i></div>
    </button>: 
    <div className='flex align-middle justify-center'>
      {selectedOrg?.logo === null ? <img src={`https://ui-avatars.com/api/?name=${selectedOrg?.name}
                        &rounded=false&length=1
                        &${
                        'background=6C9BF6'}&format=svg&color=fff`} alt='image'
                          className=' rounded-lg mx-3' height={40} width={45}  /> :<img src={organizationList.length > 0 && selectedOrg?.logo} alt={organizationList.length > 0 && selectedOrg?.logo_name} height={40} width={45} className='rounded-lg mx-3'/> }
    </div> }
    {
      isOrganisationDropdown && dropdownOpen &&  <div className='organisationDropdownCard'>
      <div className='p-2'>
      // <Search/>
      </div>
    
      <div className='organisations py-2 my-2'>
        {organizationList.length > 0 &&organizationList.map((data)=>{
            return(
            <OrganisationDropdownCard data={data} cardRef={cardRef} setDropdownOpen={setDropdownOpen} setSelectedOrganization={setSelectedOrganization} setSelectedOrg={setSelectedOrg}/>
            )
          })
        }
      </div>
      {newOrg &&
      <button className='flex gap-4 align-middle py-1 pb-2 new' style={{alignItems:'center'}} onClick={()=>{setNewOrganizationPopup(true);}}>
        <div className='text-xs plus-icon'><i class="fa-solid fa-plus"></i></div>
        <div className='organisation-heading mr-4'>New Organization</div>
      </button> }
      {newOrganizationPopup &&<div className='overlay newOrg'> <NewOrganizationPopup handleCloseAlertModal={handleCloseAlertModal} handleShowAlertModal={handleShowAlertModal} setNewOrganizationPopup={setNewOrganizationPopup} token={token} handleLogout={handleLogout}/></div>}
    </div>
    } */}
      </div>
      <div
        className={
          collapse
            ? "navoptions navoptionsall mx-4"
            : "navoptions navoptionsallcollapse mx-4"
        }
        onScroll={(e) => {
          setScrolledDist(e.target.scrollTop);
          console.log(e.target.scrollTop);
        }}
      >
        {navData?.map((data) => {
          return (
            <NavOptions
              activeOption={activeOption}
              setActiveOption={setActiveOption}
              icons={data.icon}
              title={data.title}
              collapse={collapse}
              link={data.link}
              childMenu={data.child_menu}
              scrolledDist={scrolledDist}
            />
          );
        })}
      </div>
      {displayPoweredBy && (
        <div
          style={{
            fontSize: "8px",
            color: "#ffffff",
            textAlign: "center",
            position: "absolute",
            left: 0,
            bottom: "5px",
            width: "100%",
            background:
              "var(--foundation-neutral-dark-theme-neutral-dark-theme-500, #494B4B)",
            zIndex: "99",
          }}
        >
          Powered by{" "}
          <img
            src={poweredByLogo}
            alt="Kezel"
            width="25"
            style={{ display: "inline-block" }}
          />
        </div>
      )}
    </div>
  );
}

export default NavHeader;
