import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import * as d3 from "d3";
import * as XLSX from 'xlsx';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const AddMarkers = ({
  markerData,
  showMarkers,
  value,
  groupKey,
  label,
}) => {
  const map = useMap();

  const colorScale = d3.scaleOrdinal(d3.schemeCategory10);
  const uniqueGroups = [...new Set(markerData.map((d) => d[groupKey]))];
  colorScale.domain(uniqueGroups);

  useEffect(() => {
    if (showMarkers && Array.isArray(markerData)) {
      // Remove existing markers
      map.eachLayer((layer) => {
        if (layer instanceof L.CircleMarker) {
          map.removeLayer(layer);
        }
      });

      // Add new circular markers
      markerData.forEach((stateData) => {
        const { latitude, longitude } = stateData;
        const markerColor = colorScale(stateData[groupKey]);

        // Get the dynamic label and value from stateData using the label and value keys
        const labelText = stateData[label] && stateData[label].trim() !== '' ? stateData[label] : null;
        const valueText = stateData[value] && stateData[value].trim() !== '' ? stateData[value] : null;

        // Create a circle marker
        const marker = L.circleMarker([latitude, longitude], {
          radius: 5, // Adjust the radius as needed
          fillColor: markerColor,
          color: "black", // Border color
          weight: 2, // Border weight
          opacity: 1,
          fillOpacity: 0.8,
        }).addTo(map);

        // Construct the tooltip text only if label or value is present
        let tooltipText = '';
        if (labelText) {
          tooltipText += `<b>${labelText}</b><br>`;
        }
        if (valueText) {
          tooltipText += `${valueText}`;
        }

        // Bind the tooltip only if there's content to display
        if (tooltipText.trim() !== '') {
          marker.bindTooltip(tooltipText, {
            sticky: true,
          });
        }
      });
    }
  }, [map, markerData, showMarkers, value, groupKey, label]);

  return null;
};


const Map = ({
  data,
  item,
  setOpenDeleteChartModal,
  setOpenEditChartModal,
  currPage,
  setChartSlug,
  setDashChartSlug,
}) => {
  const [noData, setNoData] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const title = item.title || "Map";
  const valueKey = item.value;
  const groupKey = item.group;
  const [showMarkers, setShowMarkers] = useState(true);

  const toggleMarkers = () => setShowMarkers(!showMarkers);

  const [mapCenter, setMapCenter] = useState([37.8, -96]); // Default center
  const [zoomLevel, setZoomLevel] = useState(5); // Default zoom level
  const [chartType, setChartType] = useState("export");

    // method - chart optons
    const UpdateType = (menu) => {
        if (menu !== "image" && menu !== "export") {
            setChartType(menu?.trim()?.toLowerCase());
        }
        else if (menu === "export") {
            // export to excel functionlity
            exportToExcel((name || "Export"), data);
        }
        else {
            // Download as Image
            //OnDownloadClick("chartDashboard");
        }
    }

// method - download chart as an Image (.png)
    // const OnDownloadClick = async (tagName) => {
    //     await htmlToImage.toPng(document.getElementsByClassName(tagName)[0])
    //         .then(function (dataUrl) {
    //             const link = document.createElement('a')
    //             link.download = "Chart"
    //             link.href = dataUrl
    //             link.click()
    //         })
    //         .catch((err) => {
    //             // nothing
    //         });
    // }

const exportToExcel = (filename, data) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
    });

    const date = new Date();

    const formattedFileName = `${filename}_${date.getMonth() + 1}_${date.getDate()}_${date.getFullYear()}_${date.getHours()}${date.getMinutes()}${date.getSeconds()}${date.getMilliseconds()}`

    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `${formattedFileName}.xlsx`);
};

const optionsExport = ["export"];

  useEffect(() => {
    if (data && data.length > 0) {
      setNoData("");
      setIsLoading(false);
    } else {
      setNoData("No data available");
    }
  }, [data]);

  useEffect(() => {
    if (item.data === "fetching") {
      setIsLoading(true);
      return;
    }
  }, [data]);

  const handleShowMoreOptions = (event) => {
    let el = document.querySelectorAll(".more-options-wrapper .more-options");

    var x = event.target
      .closest(".more-options-wrapper")
      .querySelector(".more-options");
    if (x.style.display === "none") {
      for (let i = 0; i < el.length; i++) {
        el[i].style.display = "none";
      }
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  return (
    <div>
      {noData ? (
        <>
          <div className="chart-title">{noData}</div>
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {item?.title !== "" ? (
            <div className="flex flex row justify-between">
            <div className="chart-title" style={{padding: "12px"}}>{item?.title} </div> 

            {optionsExport?.length ? 
                  <div><OverlayTrigger trigger="click" rootClose placement="bottom" overlay={<Popover id="popover-basic">
                      <Popover.Body>
                          {optionsExport?.map((menu, index) => (
                              <div key={menu} className={`${optionsExport.length !== (index + 1) ? "border-bottom chart-type-options" : ""}`}>
                                  <button className='pb-1 pt-1' onClick={() => UpdateType(menu?.trim()?.toLowerCase())} style={{ textTransform: "capitalize", border: "none", backgroundColor: "transparent" }} key={menu}>{menu}</button>
                              </div>
                          ))}
                      </Popover.Body>
                  </Popover>}>
                      <span className='pe-2' title='Filters' style={{ cursor: "pointer" }}>
                          <i className="fa-solid fa-ellipsis-vertical not-0" style={{color: "rgba(9, 8, 38, 0.6)", fontSize: "20px", marginBottom: "13px"}}></i>
                          {/* <FontAwesomeIcon style={{fontSize: "16px"}} icon={faEllipsisVertical} /> */}
                          </span>
                  </OverlayTrigger></div> : null}
            </div>


          ) : (
            <div className="no-chart-title">
              {optionsExport?.length ? 
                  <div><OverlayTrigger trigger="click" rootClose placement="bottom" overlay={<Popover id="popover-basic">
                      <Popover.Body>
                          {optionsExport?.map((menu, index) => (
                              <div key={menu} className={`${optionsExport.length !== (index + 1) ? "border-bottom chart-type-options" : ""}`}>
                                  <button className='pb-1 pt-1' onClick={() => UpdateType(menu?.trim()?.toLowerCase())} style={{ textTransform: "capitalize", border: "none", backgroundColor: "transparent" }} key={menu}>{menu}</button>
                              </div>
                          ))}
                      </Popover.Body>
                  </Popover>}>
                      <span className='pe-2' title='Filters' style={{ cursor: "pointer" }}>
                          <i className="fa-solid fa-ellipsis-vertical not-0" style={{color: "rgba(9, 8, 38, 0.6)", fontSize: "20px", marginBottom: "13px"}}></i>
                          {/* <FontAwesomeIcon style={{fontSize: "16px"}} icon={faEllipsisVertical} /> */}
                          </span>
                  </OverlayTrigger></div> : null}
            </div>
          )}
          {currPage === "manage_layout" && (
            <div
              className="text-right more-options-wrapper"
              style={{
                fontSize: "15px",
                marginTop: "-30px",
                marginBottom: "25px",
              }}
            >
              <button
                type="button"
                onClick={handleShowMoreOptions}
                title="More"
                className="btn-more-options"
              >
                <i className="fa-solid fa-ellipsis-vertical not-0"></i>
              </button>
              <div className="more-options" style={{ display: "none" }}>
                <button
                  type="button"
                  onClick={() => {
                    setOpenEditChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                 
                  }}
                >
                  <i className="fa-solid fa-pencil" aria-hidden="true"></i>{" "}
                  <span>Edit Chart</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setOpenDeleteChartModal(true);
                    setChartSlug(item.cht);
                    setDashChartSlug(item.dash_chrt);
                  }}
                >
                  <i className="fas fa-trash-alt"></i> <span>Delete Chart</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {isLoading ? (
        <i
          className="fa-solid fa-spinner fa-spin-pulse"
          style={{ marginTop: "20px" }}
        ></i>
      ) : (
        <div className="p-4">
          <MapContainer
            center={mapCenter}
            zoom={zoomLevel}
            style={{ height: 400, width: "100%" }}
            attributionControl={false}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

            {/* Render markers */}
            {Array.isArray(data) && data.length > 0 && (
              <AddMarkers
                markerData={data}
                showMarkers={showMarkers}
                title={title}
                value={item?.value}
                groupKey={item?.group}
                label={item?.label}
              />
            )}
          </MapContainer>
        </div>
      )}
    </div>
  );
};

export default Map;
